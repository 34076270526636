/* home */
/*!** _common/__common.less - STYLES COMMUNS IMPORT ***/
/*****************************************
Inline-grid system import
******************************************/
* {
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 768px) {
  .container {
    width: 690px;
  }
}
@media (min-width: 992px) {
  .container {
    width: 910px;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1110px;
  }
}
/** grid ***************************************************/
/** @col : desired columns, like bootstrap (int or float).
/** @gutter : column spacing (px)
/*************************************************************/
/** Become grid  *****************************************************/
/** @col : desired columns, like bootstrap (int or float).
/** @gutter : column spacing (px)
/** @responsive : breakpoint (px)
/*************************************************************/
/*! fancyBox v2.1.5 fancyapps.com | fancyapps.com/fancybox/#license */
.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top;
}
.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 8020;
}
.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}
.fancybox-opened {
  z-index: 8030;
}
.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}
.fancybox-outer,
.fancybox-inner {
  position: relative;
}
.fancybox-inner {
  overflow: hidden;
}
.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch;
}
.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap;
}
.fancybox-image,
.fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%;
}
.fancybox-image {
  max-width: 100%;
  max-height: 100%;
}
#fancybox-loading,
.fancybox-close,
.fancybox-prev span,
.fancybox-next span {
  background-image: url('/wp-content/themes/osiris/public/images/fancybox/fancybox_sprite.png');
}
#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060;
}
#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url('/wp-content/themes/osiris/public/images/fancybox/fancybox_loading.gif') center center no-repeat;
}
.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040;
}
.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url('/wp-content/themes/osiris/public/images/fancybox/blank.gif');
  /* helps IE */
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040;
}
.fancybox-prev {
  left: 0;
}
.fancybox-next {
  right: 0;
}
.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden;
}
.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px;
}
.fancybox-next span {
  right: 10px;
  background-position: 0 -72px;
}
.fancybox-nav:hover span {
  visibility: visible;
}
.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  visibility: hidden;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important;
}
/* Overlay helper */
.fancybox-lock {
  overflow: hidden !important;
  width: auto;
}
.fancybox-lock body {
  overflow: hidden !important;
}
.fancybox-lock-test {
  overflow-y: hidden !important;
}
.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url('/wp-content/themes/osiris/public/images/fancybox/fancybox_overlay.png');
}
.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
}
.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll;
}
/* Title helper */
.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue", Helvetica, Arial, sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050;
}
.fancybox-opened .fancybox-title {
  visibility: visible;
}
.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center;
}
.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  /* Fallback for web browsers that doesn't support RGBa */
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap;
}
.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff;
}
.fancybox-title-inside-wrap {
  padding-top: 10px;
}
.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8);
}
/*Retina graphics!*/
@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading,
  .fancybox-close,
  .fancybox-prev span,
  .fancybox-next span {
    background-image: url('/wp-content/themes/osiris/public/images/fancybox/fancybox_sprite@2x.png');
    background-size: 44px 152px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
  #fancybox-loading div {
    background-image: url('/wp-content/themes/osiris/public/images/fancybox/fancybox_loading@2x.gif');
    background-size: 24px 24px;
    /*The size of the normal image, half the size of the hi-res image*/
  }
}
/*
 * jQuery FlexSlider v2.6.3
 * http://www.woothemes.com/flexslider/
 *
 * Copyright 2012 WooThemes
 * Free to use under the GPLv2 and later license.
 * http://www.gnu.org/licenses/gpl-2.0.html
 *
 * Contributing author: Tyler Smith (@mbmufffin)
 *
 */
/* ====================================================================================================================
 * FONT-FACE
 * ====================================================================================================================*/
@font-face {
  font-family: 'flexslider-icon';
  src: url('/wp-content/themes/osiris/public/fonts/flexslider-icon.eot');
  src: url('/wp-content/themes/osiris/public/fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/flexslider-icon.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/flexslider-icon.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* ====================================================================================================================
 * RESETS
 * ====================================================================================================================*/
.flex-container a:hover,
.flex-slider a:hover {
  outline: none;
}
.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.flex-pauseplay span {
  text-transform: capitalize;
}
.flexslider li {
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  margin: inherit;
  position: inherit;
}
.flexslider li:before {
  content: none;
}
.flexslider * {
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
/* ====================================================================================================================
 * BASE STYLES
 * ====================================================================================================================*/
.flexslider {
  margin: 0;
  padding: 0;
}
.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}
.flexslider .slides img {
  width: 100%;
  display: block;
}
.flexslider .slides:after {
  content: "\0020";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
html[xmlns] .flexslider .slides {
  display: block;
}
* html .flexslider .slides {
  height: 1%;
}
.no-js .flexslider .slides > li:first-child {
  display: block;
}
/* ====================================================================================================================
 * DEFAULT THEME
 * ====================================================================================================================*/
.flexslider {
  margin: 0 0 60px;
  background: #ffffff;
  border: 4px solid #ffffff;
  position: relative;
  zoom: 1;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
  box-shadow: '' 0 1px 4px rgba(0, 0, 0, 0.2);
}
.flexslider .slides {
  zoom: 1;
}
.flexslider .slides img {
  height: auto;
  -moz-user-select: none;
}
.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.loading .flex-viewport {
  max-height: 300px;
}
.carousel li {
  margin-right: 5px;
}
.flex-direction-nav {
  *height: 0;
}
.flex-direction-nav a {
  text-decoration: none;
  display: block;
  width: 40px;
  height: 40px;
  margin: -30px 0 0;
  position: absolute;
  top: 50%;
  z-index: 0;
  overflow: hidden;
  opacity: 0;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.8);
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flex-direction-nav a:before {
  font-size: 60px;
  display: inline-block;
  font-family: 'osiris';
  content: "\e901";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: white;
}
.flex-direction-nav a.flex-next:before {
  font-family: 'osiris';
  content: "\e900";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: white;
}
.flex-direction-nav .flex-prev {
  left: -50px;
}
.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}
.flexslider:hover .flex-direction-nav .flex-prev {
  opacity: 0.7;
  left: 10px;
}
.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}
.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: 10px;
}
.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}
.flex-direction-nav .flex-disabled {
  opacity: 0!important;
  filter: alpha(opacity=0);
  cursor: default;
  z-index: -1;
}
.flex-pauseplay a {
  display: block;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 5px;
  left: 10px;
  opacity: 0.8;
  z-index: 10;
  overflow: hidden;
  cursor: pointer;
  color: #000;
}
.flex-pauseplay a:before {
  font-family: "flexslider-icon";
  font-size: 20px;
  display: inline-block;
  content: '\f004';
}
.flex-pauseplay a:hover {
  opacity: 1;
}
.flex-pauseplay a.flex-play:before {
  content: '\f003';
}
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}
.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  text-indent: -9999px;
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -o-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
}
.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}
.flex-control-paging li a.flex-active {
  background: #000;
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}
.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}
.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}
.flex-control-thumbs img {
  width: 100%;
  height: auto;
  display: block;
  opacity: .7;
  cursor: pointer;
  -moz-user-select: none;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.flex-control-thumbs img:hover {
  opacity: 1;
}
.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}
/* ====================================================================================================================
 * RESPONSIVE
 * ====================================================================================================================*/
@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 10px;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}
.flexslider {
  margin: 0;
  background-color: transparent;
  border: none;
}
#slider,
#slider ul,
#slider li {
  background-color: #f1f1f1;
}
#slider .flex-active-slide {
  cursor: pointer;
}
#carousel .slides > li {
  cursor: pointer;
  border: 2px solid #fff;
  opacity: .3;
}
#carousel .slides > li.flex-active-slide {
  opacity: 1;
}
.flex-direction-nav a {
  height: 75px;
}
.flex-direction-nav a:before {
  margin-top: 14px;
}
/*****************************************/
/** Mixins import 
******************************************/
/* Rend une image en noir et blanc */
/*****************************************
Animations import & mixins
******************************************/
@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}
@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }
  40% {
    -moz-transform: translateY(-30px);
  }
  60% {
    -moz-transform: translateY(-15px);
  }
}
@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }
  40% {
    -o-transform: translateY(-30px);
  }
  60% {
    -o-transform: translateY(-15px);
  }
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
  }
  70% {
    -webkit-transform: scale(0.9);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes bounceIn {
  0% {
    opacity: 0;
    -moz-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -moz-transform: scale(1.05);
  }
  70% {
    -moz-transform: scale(0.9);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@-o-keyframes bounceIn {
  0% {
    opacity: 0;
    -o-transform: scale(0.3);
  }
  50% {
    opacity: 1;
    -o-transform: scale(1.05);
  }
  70% {
    -o-transform: scale(0.9);
  }
  100% {
    -o-transform: scale(1);
  }
}
@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}
.bounceIn {
  -webkit-animation-name: bounceIn;
  -moz-animation-name: bounceIn;
  -o-animation-name: bounceIn;
  animation-name: bounceIn;
}
@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
  }
  80% {
    -webkit-transform: translateY(-10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(30px);
  }
  80% {
    -moz-transform: translateY(-10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInDown {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(30px);
  }
  80% {
    -o-transform: translateY(-10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInDown {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(30px);
  }
  80% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
.bounceInDown {
  -webkit-animation-name: bounceInDown;
  -moz-animation-name: bounceInDown;
  -o-animation-name: bounceInDown;
  animation-name: bounceInDown;
}
@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
  }
  80% {
    -webkit-transform: translateX(-10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(30px);
  }
  80% {
    -moz-transform: translateX(-10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(30px);
  }
  80% {
    -o-transform: translateX(-10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInLeft {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(30px);
  }
  80% {
    transform: translateX(-10px);
  }
  100% {
    transform: translateX(0);
  }
}
.bounceInLeft {
  -webkit-animation-name: bounceInLeft;
  -moz-animation-name: bounceInLeft;
  -o-animation-name: bounceInLeft;
  animation-name: bounceInLeft;
}
@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
  }
  80% {
    -webkit-transform: translateX(10px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes bounceInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-30px);
  }
  80% {
    -moz-transform: translateX(10px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@-o-keyframes bounceInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateX(-30px);
  }
  80% {
    -o-transform: translateX(10px);
  }
  100% {
    -o-transform: translateX(0);
  }
}
@keyframes bounceInRight {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  60% {
    opacity: 1;
    transform: translateX(-30px);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
.bounceInRight {
  -webkit-animation-name: bounceInRight;
  -moz-animation-name: bounceInRight;
  -o-animation-name: bounceInRight;
  animation-name: bounceInRight;
}
@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
  }
  80% {
    -webkit-transform: translateY(10px);
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes bounceInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateY(-30px);
  }
  80% {
    -moz-transform: translateY(10px);
  }
  100% {
    -moz-transform: translateY(0);
  }
}
@-o-keyframes bounceInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    -o-transform: translateY(-30px);
  }
  80% {
    -o-transform: translateY(10px);
  }
  100% {
    -o-transform: translateY(0);
  }
}
@keyframes bounceInUp {
  0% {
    opacity: 0;
    transform: translateY(2000px);
  }
  60% {
    opacity: 1;
    transform: translateY(-30px);
  }
  80% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0);
  }
}
.bounceInUp {
  -webkit-animation-name: bounceInUp;
  -moz-animation-name: bounceInUp;
  -o-animation-name: bounceInUp;
  animation-name: bounceInUp;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  -webkit-animation-name: fadeIn;
  -moz-animation-name: fadeIn;
  -o-animation-name: fadeIn;
  animation-name: fadeIn;
}
@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDown {
  0% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDown {
  0% {
    opacity: 0;
    -ms-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -ms-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  -moz-animation-name: fadeInDown;
  -o-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -o-transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    transform: translateY(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInDownBig {
  -webkit-animation-name: fadeInDownBig;
  -moz-animation-name: fadeInDownBig;
  -o-animation-name: fadeInDownBig;
  animation-name: fadeInDownBig;
}
@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -moz-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -o-transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  -moz-animation-name: fadeInLeft;
  -o-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -o-transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    transform: translateX(-2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInLeftBig {
  -webkit-animation-name: fadeInLeftBig;
  -moz-animation-name: fadeInLeftBig;
  -o-animation-name: fadeInLeftBig;
  animation-name: fadeInLeftBig;
}
@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRight {
  0% {
    opacity: 0;
    -moz-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRight {
  0% {
    opacity: 0;
    -o-transform: translateX(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  -moz-animation-name: fadeInRight;
  -o-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -moz-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0);
  }
}
@-o-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -o-transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    -o-transform: translateX(0);
  }
}
@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    transform: translateX(2000px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  -moz-animation-name: fadeInRightBig;
  -o-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUp {
  0% {
    opacity: 0;
    -moz-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUp {
  0% {
    opacity: 0;
    -o-transform: translateY(20px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(200px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@-moz-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -moz-transform: translateY(200px);
  }
  100% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
}
@-o-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -o-transform: translateY(200px);
  }
  100% {
    opacity: 1;
    -o-transform: translateY(0);
  }
}
@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.fadeInUpBig {
  -webkit-animation-name: fadeInUpBig;
  -moz-animation-name: fadeInUpBig;
  -o-animation-name: fadeInUpBig;
  animation-name: fadeInUpBig;
}
@-webkit-keyframes {
  0% {
    opacity: 0;
    -webkit-transform: translate(-2000px, 2000px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate(0, 0);
  }
}
@-moz-keyframes fadeInUpRight {
  0% {
    opacity: 0;
    -moz-transform: translate(-2000px, 2000px);
  }
  100% {
    opacity: 1;
    -moz-transform: translate(0, 0);
  }
}
@-o-keyframes fadeInUpRight {
  0% {
    opacity: 0;
    -ms-transform: translate(-2000px, 2000px);
  }
  100% {
    opacity: 1;
    -ms-transform: translate(0, 0);
  }
}
@keyframes fadeInUpRight {
  0% {
    opacity: 0;
    transform: translate(-2000px, 2000px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.fadeInUpRight {
  -webkit-animation-name: fadeInUpRight;
  -moz-animation-name: fadeInUpRight;
  -o-animation-name: fadeInUpRight;
  animation-name: fadeInUpRight;
}
@-webkit-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-moz-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-o-keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
}
@-moz-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -moz-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -moz-transform: translateY(-20px);
  }
}
@-o-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -o-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -o-transform: translateY(-20px);
  }
}
@keyframes fadeOutUp {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}
.fadeOutUp {
  -webkit-animation-name: fadeOutUp;
  -moz-animation-name: fadeOutUp;
  -o-animation-name: fadeOutUp;
  animation-name: fadeOutUp;
}
.wpb_start_animation.wpb_top-to-bottom {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.wpb_start_animation.wpb_top-to-bottom.animated {
  -webkit-animation-name: wpb_ttb 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-animation-name: wpb_ttb 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation-name: wpb_ttb 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: wpb_ttb 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  /* Chrome, Safari, Opera */
  animation-timing-function: ease;
}
.wpb_start_animation.wpb_bottom-to-top {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.wpb_start_animation.wpb_bottom-to-top.animated {
  -webkit-animation-name: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-animation-name: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation-name: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: wpb_btt 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  /* Chrome, Safari, Opera */
  animation-timing-function: ease;
}
.wpb_start_animation.wpb_left-to-right {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.wpb_start_animation.wpb_left-to-right.animated {
  -webkit-animation-name: wpb_ltr 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-animation-name: wpb_ltr 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation-name: wpb_ltr 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: wpb_ltr 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  /* Chrome, Safari, Opera */
  animation-timing-function: ease;
}
.wpb_start_animation.wpb_right-to-left {
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.wpb_start_animation.wpb_right-to-left.animated {
  -webkit-animation-name: wpb_rtl 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-animation-name: wpb_rtl 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation-name: wpb_rtl 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: wpb_rtl 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  /* Chrome, Safari, Opera */
  animation-timing-function: ease;
}
.wpb_start_animation.wpb_appear {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-opacity: 1;
  -moz-opacity: 1;
  opacity: 1;
  filter: alpha(opacity=100);
}
.wpb_start_animation.wpb_appear.animated {
  -webkit-animation-name: wpb_appear 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-animation-name: wpb_appear 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-animation-name: wpb_appear 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  animation-name: wpb_appear 0.7s 1 cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: ease;
  /* Chrome, Safari, Opera */
  animation-timing-function: ease;
}
/* Top to bottom keyframes */
@-webkit-keyframes topToBottom {
  0% {
    -webkit-transform: translate(0, -10%);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes topToBottom {
  0% {
    -moz-transform: translate(0, -10%);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -moz-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-o-keyframes topToBottom {
  0% {
    -o-transform: translate(0, -10%);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -o-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes topToBottom {
  0% {
    -webkit-transform: translate(0, -10%);
    -moz-transform: translate(0, -10%);
    -ms-transform: translate(0, -10%);
    -o-transform: translate(0, -10%);
    transform: translate(0, -10%);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
/* Bottom to top keyframes */
@-webkit-keyframes bottomToTop {
  0% {
    -webkit-transform: translate(0, 10%);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes bottomToTop {
  0% {
    -moz-transform: translate(0, 10%);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -moz-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-o-keyframes bottomToTop {
  0% {
    -o-transform: translate(0, 10%);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -o-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes bottomToTop {
  0% {
    -webkit-transform: translate(0, 10%);
    -moz-transform: translate(0, 10%);
    -ms-transform: translate(0, 10%);
    -o-transform: translate(0, 10%);
    transform: translate(0, 10%);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
/* Left to right keyframes */
@-webkit-keyframes leftToRight {
  0% {
    -webkit-transform: translate(-10%, 0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes leftToRight {
  0% {
    -moz-transform: translate(-10%, 0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -moz-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-o-keyframes leftToRight {
  0% {
    -o-transform: translate(-10%, 0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -o-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes leftToRight {
  0% {
    -webkit-transform: translate(-10%, 0);
    -moz-transform: translate(-10%, 0);
    -ms-transform: translate(-10%, 0);
    -o-transform: translate(-10%, 0);
    transform: translate(-10%, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
/* Right to left keyframes */
@-webkit-keyframes rightToLeft {
  0% {
    -webkit-transform: translate(10%, 0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes rightToLeft {
  0% {
    -moz-transform: translate(10%, 0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -moz-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-o-keyframes rightToLeft {
  0% {
    -o-transform: translate(10%, 0);
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -o-transform: translate(0, 0);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes rightToLeft {
  0% {
    -webkit-transform: translate(10%, 0);
    -moz-transform: translate(10%, 0);
    -ms-transform: translate(10%, 0);
    -o-transform: translate(10%, 0);
    transform: translate(10%, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 0;
    -moz-opacity: 0;
    opacity: 0;
    filter: alpha(opacity=0);
  }
  100% {
    -webkit-transform: translate(0, 0);
    -moz-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
/* Appear from center keyframes */
@-webkit-keyframes appear {
  0% {
    -webkit-transform: scale(0.5);
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
    filter: alpha(opacity=10);
  }
  100% {
    -webkit-transform: scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-moz-keyframes appear {
  0% {
    -moz-transform: scale(0.5);
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
    filter: alpha(opacity=10);
  }
  100% {
    -moz-transform: scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@-o-keyframes appear {
  0% {
    -o-transform: scale(0.5);
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
    filter: alpha(opacity=10);
  }
  100% {
    -o-transform: scale(1);
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
@keyframes appear {
  0% {
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 0.1;
    -moz-opacity: 0.1;
    opacity: 0.1;
    filter: alpha(opacity=10);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-opacity: 1;
    -moz-opacity: 1;
    opacity: 1;
    filter: alpha(opacity=100);
  }
}
/*!** _elements/_iconfont.less - Fichier destiné aux fonts d'icones ***/
@font-face {
  font-family: 'osiris';
  src: url('/wp-content/themes/osiris/public/fonts/iconfont/osiris.eot');
  src: url('/wp-content/themes/osiris/public/fonts/iconfont/osiris.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/iconfont/osiris.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/iconfont/osiris.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/iconfont/osiris.svg#osiris') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*!** _elements/_fonts.less - Fichier destiné à l'import de font et aux mixins associés ***/
@font-face {
  font-family: 'kanit-bold';
  src: url('/wp-content/themes/osiris/public/fonts/kanit-bold.eot');
  src: url('/wp-content/themes/osiris/public/fonts/kanit-bold.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/kanit-bold.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/kanit-bold.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/kanit-bold.svg#kanit-bold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kanit-light';
  src: url('/wp-content/themes/osiris/public/fonts/kanit-light.eot');
  src: url('/wp-content/themes/osiris/public/fonts/kanit-light.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/kanit-light.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/kanit-light.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/kanit-light.svg#kanit-light') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kanit-regular';
  src: url('/wp-content/themes/osiris/public/fonts/kanit-regular.eot');
  src: url('/wp-content/themes/osiris/public/fonts/kanit-regular.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/kanit-regular.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/kanit-regular.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/kanit-regular.svg#kanit-regular') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kanit-medium';
  src: url('/wp-content/themes/osiris/public/fonts/kanit-medium.eot');
  src: url('/wp-content/themes/osiris/public/fonts/kanit-medium.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/kanit-medium.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/kanit-medium.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/kanit-medium.svg#kanit-medium') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kanit-thin';
  src: url('/wp-content/themes/osiris/public/fonts/kanit-thin.eot');
  src: url('/wp-content/themes/osiris/public/fonts/kanit-thin.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/kanit-thin.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/kanit-thin.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/kanit-thin.svg#kanit-thin') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kanit-semibold';
  src: url('/wp-content/themes/osiris/public/fonts/kanit-semibold.eot');
  src: url('/wp-content/themes/osiris/public/fonts/kanit-semibold.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/kanit-semibold.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/kanit-semibold.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/kanit-semibold.svg#kanit-semibold') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'kanit-extralight';
  src: url('/wp-content/themes/osiris/public/fonts/kanit-extralight.eot');
  src: url('/wp-content/themes/osiris/public/fonts/kanit-extralight.eot?#iefix') format('embedded-opentype'), url('/wp-content/themes/osiris/public/fonts/kanit-extralight.woff') format('woff'), url('/wp-content/themes/osiris/public/fonts/kanit-extralight.ttf') format('truetype'), url('/wp-content/themes/osiris/public/fonts/kanit-extralight.svg#kanit-extralight') format('svg');
  font-weight: normal;
  font-style: normal;
}
/*** Fichier destiné aux styles des titres ***/
h1,
.h1 {
  font-size: 55px;
  line-height: 60px;
}
@media (max-width: 992px) {
  h1,
  .h1 {
    font-size: 50px;
    line-height: 55px;
  }
}
h2,
.h2 {
  font-size: 27px;
  line-height: 32px;
}
@media (max-width: 992px) {
  h2,
  .h2 {
    font-size: 25px;
    line-height: 30px;
  }
}
h3,
.h3 {
  font-size: 23px;
  line-height: 28px;
}
@media (max-width: 992px) {
  h3,
  .h3 {
    font-size: 23px;
    line-height: 28px;
  }
}
h4,
.h4 {
  font-size: 22px;
  line-height: 27px;
}
@media (max-width: 768px) {
  h4,
  .h4 {
    font-size: 20px;
    line-height: 25px;
  }
}
h5,
.h5 {
  font-size: 22px;
  line-height: 27px;
}
@media (max-width: 768px) {
  h5,
  .h5 {
    font-size: 20px;
    line-height: 25px;
  }
}
.home-h2 {
  font-size: 50px;
  line-height: 55px;
  font-family: 'kanit-extralight';
  font-weight: 100;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (max-width: 992px) {
  .home-h2 {
    font-size: 46px;
    line-height: 51px;
  }
}
@media (max-width: 768px) {
  .home-h2 {
    font-size: 42px;
    line-height: 47px;
  }
}
@media (max-width: 450px) {
  .home-h2 {
    font-size: 33px;
    line-height: 38px;
  }
}
.home-h3 {
  font-size: 25px;
  line-height: 30px;
  font-family: 'kanit-extralight';
  font-weight: 100;
  max-width: 80%;
  margin: auto;
  margin-bottom: 5px;
}
@media (max-width: 992px) {
  .home-h3 {
    font-size: 21px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .home-h3 {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 450px) {
  .home-h3 {
    font-size: 18px;
    line-height: 23px;
  }
}
@media (max-width: 768px) {
  .home-h3 {
    max-width: 500px;
  }
}
.home-h3 p {
  font-size: 25px;
  line-height: 30px;
  font-family: 'kanit-extralight';
  font-weight: 100;
}
@media (max-width: 992px) {
  .home-h3 p {
    font-size: 21px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .home-h3 p {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 450px) {
  .home-h3 p {
    font-size: 18px;
    line-height: 23px;
  }
}
.home-h4 {
  font-size: 27px;
  line-height: 32px;
}
@media (max-width: 992px) {
  .home-h4 {
    font-size: 25px;
    line-height: 30px;
  }
}
@media (max-width: 768px) {
  .home-h4 {
    font-size: 20px;
    line-height: 25px;
  }
}
section.title {
  padding: 0;
  margin: 0;
  text-align: center;
}
section.subtitle {
  padding: 0;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
.title-styled {
  padding-top: 30px;
  position: relative;
  display: inline-block;
}
.title-styled:before {
  font-family: 'osiris';
  content: "\e909";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 30px;
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.contact-h2 {
  margin: 40px 0;
}
.pad-bot {
  padding-bottom: 40px;
}
.landing-page h2 {
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 30px;
  line-height: 35px;
  line-height: 30px;
  color: #0e939a;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 12px;
  margin: 0 0 30px;
}
@media (max-width: 992px) {
  .landing-page h2 {
    font-size: 25px;
    line-height: 30px;
  }
}
.landing-page h2:after {
  content: "";
  background-color: #0e939a;
  width: 60px;
  height: 2px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.map-text-content {
  padding-top: 7%;
}
.map-text h2 {
  font-family: 'kanit-extralight';
  font-weight: 100;
  font-size: 35px;
  line-height: 40px;
  color: #0e939a;
  position: relative;
  padding: 20px 0 0;
  margin: 0 0 15px;
  text-align: center;
}
.map-text h2:before {
  font-family: 'osiris';
  content: "\e90e";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 14px;
  line-height: 19px;
  line-height: 14px;
  color: #2d2c2b;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}
/*- variables -*/
/*color*/
/*  text */
.row {
  margin-left: -15px;
  margin-right: -15px;
}
/* transition */
@media (max-width: 650px) {
  .countrybox-col.col {
    margin-bottom: 0;
  }
}
.countrybox-col.col.animated {
  -webkit-animation-name: bottomToTop;
  -moz-animation-name: bottomToTop;
  -o-animation-name: bottomToTop;
  animation-name: bottomToTop;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  /* Chrome, Safari, Opera */
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.countrybox-link {
  display: block;
  position: relative;
}
@media (max-width: 650px) {
  .countrybox-link {
    margin-bottom: -86px;
  }
}
.countrybox-link:hover img {
  -webkit-transform: scale(1.05);
  -moz-transform: scale(1.05);
  -ms-transform: scale(1.05);
  -o-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  opacity: 0.8;
}
.countrybox-link:hover .countrybox-title {
  bottom: 50px;
}
@media (max-width: 650px) {
  .countrybox-link:hover .countrybox-title {
    bottom: 85px;
  }
}
.countrybox-img {
  display: block;
  overflow: hidden;
}
.countrybox-img img {
  max-width: 100%;
  height: auto;
  -webkit-transition: all all 0.5s ease ease;
  -moz-transition: all all 0.5s ease ease;
  -ms-transition: all all 0.5s ease ease;
  -o-transition: all all 0.5s ease ease;
  transition: all all 0.5s ease ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  transform-origin: bottom center;
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -ms-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.countrybox-title {
  position: relative;
  bottom: 0;
  text-align: center;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  padding-bottom: 35px;
  padding-top: 15px;
  background-color: white;
  letter-spacing: 1.2px;
  -webkit-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -moz-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -ms-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -o-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -webkit-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
@media (max-width: 650px) {
  .countrybox-title {
    padding: 20px 2% 40px 2%;
    vertical-align: top;
    bottom: 85px;
    margin: auto;
    background-color: rgba(255, 255, 255, 0.7);
  }
}
.countrybox-title:before {
  -webkit-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -moz-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -ms-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -o-transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  transition: all all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -webkit-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  font-family: 'osiris';
  content: "\e90c";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  font-size: 20px;
}
.flatbox-col {
  margin-bottom: 15px;
}
.flatbox-col.animated {
  -webkit-animation-name: fadeInUp;
  -moz-animation-name: fadeInUp;
  -o-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
  /* Chrome, Safari, Opera */
  animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
}
.flatbox-content {
  padding: 10px 10px 85px;
  border: 1px solid #C0C0C0;
  background-color: white;
  position: relative;
}
.flatbox-content.hover img {
  -webkit-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -moz-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -ms-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -o-transform: translate(-50%, -50%) scale3d(1, 1, 1);
  transform: translate(-50%, -50%) scale3d(1, 1, 1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  opacity: 0.7;
}
.flatbox-content.hover .flatbox-imgcontainer:after {
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
  -moz-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
  -ms-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
  -o-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, 120%, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flatbox-no-result {
  padding: 0px 85px 40px;
}
@media (max-width: 768px) {
  .flatbox-no-result {
    padding: 0px 20px 40px;
  }
}
.flatbox-no-result--text {
  font-size: 18px;
  line-height: 23px;
  margin: 0;
  padding: 0;
  margin-bottom: 15px;
}
.flatbox-flag {
  background-color: #D8AF4E;
  padding: 1rem;
  z-index: 10;
  position: absolute;
  min-width: 250px;
  width: 250px;
  max-width: 250px;
  transform: rotate(38deg);
  text-align: center;
  top: 30px;
  right: -55px;
}
.flatbox-flag span {
  color: white;
  text-transform: uppercase;
}
.flatbox-imgcontainer {
  background-color: #0e939a;
  width: 100%;
  overflow: hidden;
  height: 210px;
  position: relative;
}
@media (max-width: 1300px) {
  .flatbox-imgcontainer {
    height: 180px;
  }
}
.flatbox-imgcontainer:after {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  content: '';
  -webkit-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -moz-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -ms-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -o-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -webkit-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  -webkit-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  -moz-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  -ms-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  -o-transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  transform: scale3d(1.9, 1.4, 1) rotate3d(0, 0, 1, 45deg) translate3d(0, -100%, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flatbox-imgcontainer img {
  width: 110%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -moz-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -ms-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -o-transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  transition: all all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1) ease;
  -webkit-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -moz-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -ms-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -o-transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: all 0.8s cubic-bezier(0.215, 0.61, 0.355, 1);
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  -webkit-transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1.1);
  -moz-transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1.1);
  -ms-transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1.1);
  -o-transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1.1);
  transform: translate(-50%, -50%) scale3d(1.1, 1.1, 1.1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
@media (max-width: 992px) {
  .flatbox-imgcontainer img {
    width: 130%;
  }
}
@media (max-width: 768px) {
  .flatbox-imgcontainer img {
    width: 110%;
  }
}
.flatbox-title {
  font-family: 'kanit-semibold';
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
  text-align: left;
  margin: 15px 0 5px;
  padding-bottom: 0;
}
.flatbox-title:after {
  content: none;
}
.flatbox-info {
  margin-top: 15px;
  display: block;
}
.flatbox-info a {
  color: #0e939a;
  font-size: 17px;
  line-height: 22px;
}
.flatbox-country {
  font-family: 'kanit-semibold';
  font-weight: 400;
  text-transform: uppercase;
  position: relative;
  padding-right: 11px;
}
.flatbox-country-with-residence:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  height: 60%;
  width: 2px;
  background-color: #0e939a;
}
.flatbox-residence {
  padding-left: 3px;
}
.flatbox-description {
  font-size: 16px;
  line-height: 21px;
}
.flatbox-price-lk-cont {
  padding: 5px 10px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.flatbox-price {
  width: 50%;
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 5px;
  font-size: 14px;
  line-height: 19px;
}
.flatbox-price strong {
  display: block;
  font-size: 22px;
  line-height: 27px;
  font-weight: 400;
  font-family: 'kanit-medium';
}
.flatbox-linkcontainer {
  width: 48%;
  display: inline-block;
  vertical-align: middle;
}
.flatbox-link.btn {
  float: right;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: 0.5px;
  padding: 10px 20px;
}
.flatbox.btn-line {
  position: relative;
  margin-top: 40px;
  padding: 10px 70px;
}
.flatbox.btn-line:before {
  position: absolute;
  color: black;
  font-family: 'osiris';
  content: "\e90c";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  left: 5%;
  font-size: 20px;
  line-height: 25px;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  line-height: 20px;
}
.flatbox.btn-line:hover:before {
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  color: white;
}
.newsbloc {
  text-align: left;
}
.newsbloc-col.hover img {
  -webkit-transform: scale(1.15);
  -moz-transform: scale(1.15);
  -ms-transform: scale(1.15);
  -o-transform: scale(1.15);
  transform: scale(1.15);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  opacity: 0.7;
}
.newsbloc-col.hover .newsbloc-title a,
.newsbloc-col.hover .btn-more {
  color: #0e939a;
}
.newsbloc-col.hover .btn-more:after {
  right: 0;
}
.newsbloc-img {
  text-align: center;
  display: block;
  max-height: 210px;
  overflow: hidden;
}
.newsbloc-img img {
  -webkit-transition: all all 0.5s ease ease;
  -moz-transition: all all 0.5s ease ease;
  -ms-transition: all all 0.5s ease ease;
  -o-transition: all all 0.5s ease ease;
  transition: all all 0.5s ease ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  -webkit-transform: scale(1.25);
  -moz-transform: scale(1.25);
  -ms-transform: scale(1.25);
  -o-transform: scale(1.25);
  transform: scale(1.25);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  max-width: 100%;
}
.newsbloc-title {
  margin-bottom: 0;
  margin-top: 10px;
  font-size: 19px;
  line-height: 24px;
}
@media (max-width: 992px) {
  .newsbloc-title {
    font-size: 17px;
    line-height: 22px;
  }
}
.newsbloc-title a {
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.2px;
  -webkit-transition: all all 0.5s ease ease;
  -moz-transition: all all 0.5s ease ease;
  -ms-transition: all all 0.5s ease ease;
  -o-transition: all all 0.5s ease ease;
  transition: all all 0.5s ease ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
@media (max-width: 992px) {
  .newsbloc-title a {
    font-size: 17px;
    line-height: 22px;
  }
}
.newsbloc-date {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 14px;
  line-height: 19px;
  position: relative;
  padding-right: 5px;
}
.newsbloc-date:before {
  content: '';
  height: 80%;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  right: -3px;
}
.newsbloc-cat {
  vertical-align: top;
}
.newsbloc-cat a {
  vertical-align: top;
  padding-left: 10px;
  margin: 0;
  display: inline-block;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 14px;
  line-height: 19px;
  color: #0e939a;
}
.newsbloc-text * {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 17px;
  line-height: 22px;
  margin-top: 5px;
  margin-bottom: 2px;
}
.newsbloc.nothave_excerpt .newsbloc-date-container {
  margin-top: 15px;
  margin-bottom: 15px;
}
.home-header-block {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;
  margin-bottom: 3rem;
}
.home-header-block::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 80%;
  background-color: #3eb1b7;
}
@media (max-width: 768px) {
  .home-header-block {
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
}
.home-header-title {
  max-width: 55%;
  text-align: center;
  color: #ffffff;
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 32px;
  line-height: 37px;
}
.home-header-title-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
}
@media (max-width: 768px) {
  .home-header-title-wrapper {
    padding-bottom: 1rem;
  }
}
@media (max-width: 768px) {
  .home-header-title {
    max-width: 90%;
  }
}
@media (max-width: 992px) {
  .home-header-title {
    font-size: 28px;
    line-height: 33px;
  }
}
@media (max-width: 768px) {
  .home-header-title {
    font-size: 25px;
    line-height: 30px;
  }
}
.home-header-title strong {
  text-align: center;
  color: #ffffff;
  font-size: 32px;
  line-height: 37px;
  font-weight: 600;
}
@media (max-width: 992px) {
  .home-header-title strong {
    font-size: 28px;
    line-height: 33px;
  }
}
@media (max-width: 768px) {
  .home-header-title strong {
    font-size: 25px;
    line-height: 30px;
  }
}
.home-header-destination-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
}
.home-header-destination-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  align-items: stretch;
  max-height: 236px;
}
.home-header-destination-list-item {
  position: relative;
  min-width: 25%;
}
@media (max-width: 768px) {
  .home-header-destination-list-item {
    width: 47%;
  }
}
@media (max-width: 768px) {
  .home-header-destination-list {
    flex-wrap: wrap;
  }
}
.home-header-destination-flag {
  position: absolute;
  top: -10px;
  left: 10px;
  height: 40px;
}
.home-header-destination-image {
  height: 100%;
  object-fit: cover;
  border: 2px solid #ffffff;
  border-radius: 10px;
}
@supports (-webkit-touch-callout: none) {
  @media (max-width: 768px) {
    max-height: 110px;
  }
}
.home-header-destination-label {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-left: 2px;
  margin-right: 2px;
  margin-bottom: 2px;
  color: #ffffff;
  position: absolute;
  bottom: 0;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 99%;
  height: 33%;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 24px;
  line-height: 29px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
}
@media (max-width: 992px) {
  .home-header-destination-label {
    font-size: 18px;
    line-height: 23px;
  }
}
@media (max-width: 768px) {
  .home-header-destination-label {
    font-size: 16px;
    line-height: 21px;
  }
}
.home-header-search-wrapper {
  position: relative;
  padding-top: 1rem;
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home-header-search-text {
  font-size: 20px;
  line-height: 25px;
  width: 100%;
  text-align: left;
  color: #ffffff;
  font-weight: 400;
  font-family: 'kanit-medium';
}
@media (max-width: 992px) {
  .home-header-search-text {
    font-size: 16px;
    line-height: 21px;
  }
}
@media (max-width: 768px) {
  .home-header-search-text {
    font-size: 14px;
    line-height: 19px;
  }
}
.home-header-search-text * {
  font-weight: 400;
  font-family: 'kanit-medium';
  color: #ffffff;
}
.home-header-search-form {
  margin-top: 0 !important;
  border: 2px solid #3eb1b7;
}
.home-header-search-form::after,
.home-header-search-form::before {
  display: none !important;
}
.home-property-list-block {
  background-color: rgba(237, 237, 237, 0.45);
  padding: 24px;
  padding-left: 72px;
  padding-right: 72px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .home-property-list-block {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.home-property-list-block .flex-direction-nav a {
  opacity: 1 !important;
  color: #0e939a;
  z-index: 10;
  border: none;
  border-radius: 4px;
  background-color: #0e939a;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 42px;
  width: 42px;
}
.home-property-list-block .flex-direction-nav a::before {
  content: "\e907";
  font-size: 20px;
  color: #ffffff;
  margin-top: unset !important;
  margin-bottom: 10px;
}
@media (max-width: 768px) {
  .home-property-list-block .flex-direction-nav a {
    top: unset;
    bottom: 40px;
  }
}
.home-property-list-block .flex-disabled {
  border: 1px solid #0e939a !important;
  background-color: #ffffff !important;
  color: #ffffff !important;
}
.home-property-list-block .flex-disabled::before {
  content: "\e907" !important;
  color: #0e939a !important;
}
.home-property-list-block .flex-next {
  right: 35px !important;
}
@media (max-width: 768px) {
  .home-property-list-block .flex-next {
    right: unset !important;
    left: 100px;
  }
}
.home-property-list-block .flex-prev {
  left: 35px !important;
}
.home-property-list-block .flex-prev::before {
  rotate: 180deg;
}
.home-property-list-title {
  text-align: center;
  color: #2d2c2a;
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
}
.home-property-list-title-wrapper {
  width: 100%;
  text-align: center;
}
@media (max-width: 992px) {
  .home-property-list-title {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 768px) {
  .home-property-list-title {
    font-size: 18px;
    line-height: 23px;
  }
}
.home-property-list-item {
  padding: 0.25rem !important;
}
.home-property-list-item-empty {
  width: 100%;
  text-align: center;
}
@media (max-width: 768px) {
  .home-property-list-item .flatbox-content {
    padding: 10px;
  }
}
@media (max-width: 768px) {
  .home-property-list-item .flatbox-price-lk-cont {
    display: inline-flex;
    flex-direction: row-reverse;
    gap: 0.25rem;
    background-color: #ffffff;
  }
}
.home-property-list-link {
  cursor: pointer;
  color: #0e939a;
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
}
.home-property-list-link-wrapper {
  width: 100%;
  text-align: center;
  padding: 1rem;
}
@media (max-width: 992px) {
  .home-property-list-link {
    font-size: 16px;
    line-height: 21px;
  }
}
@media (max-width: 768px) {
  .home-property-list-link {
    font-size: 14px;
    line-height: 19px;
  }
}
.home-property-list-link:hover {
  text-decoration: underline;
}
/*!** _elements/_base.less - Fichier destiné aux styles de bases ***/
html {
  overflow-x: hidden;
}
body {
  margin: 0;
  moz-osx-font-smoothing: grayscale;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  font-family: sans-serif;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  position: relative;
  overflow-x: hidden;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
  margin-top: 10px;
}
@media (max-width: 650px) {
  body {
    margin: 0;
  }
}
body.no-scroll {
  overflow: hidden;
}
a {
  background-color: transparent;
}
a:active,
a:hover,
a:focus {
  outline: 0;
}
img {
  outline: none;
  outline-color: transparent;
  outline-width: 0;
  box-shadow: none;
  border: none;
}
.align-center {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.align-right {
  display: inline-block;
  width: 100%;
  text-align: right;
}
.align-left {
  display: inline-block;
  width: 100%;
  text-align: left;
}
ul.no-list {
  padding-left: 0;
  list-style-type: none;
}
ul.list-inline {
  font-size: 0;
}
ul.list-inline li {
  display: inline-block;
}
* {
  font-family: 'kanit-light';
  font-weight: 100;
  color: #2d2c2a;
  font-size: 14px;
  line-height: 19px;
}
/*!** _elements/_buttons.less - Fichier destiné aux styles des boutons ***/
a {
  text-decoration: none;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
a.typeform-share.button {
  color: white;
  background-color: #0e939a !important;
  border: 2px solid #0e939a !important;
  background-image: none !important;
  display: inline-block !important;
  padding: 12px 25px !important;
  text-decoration: none !important;
  border-radius: 3px !important;
  transition: all .4s ease !important;
  text-transform: uppercase !important;
  font-size: 14px !important;
  font-family: 'kanit-semibold';
  font-weight: 400;
  letter-spacing: 1px !important;
  cursor: pointer !important;
  -webkit-transition: all all 0.3s ease ease !important;
  -moz-transition: all all 0.3s ease ease !important;
  -ms-transition: all all 0.3s ease ease !important;
  -o-transition: all all 0.3s ease ease !important;
  transition: all all 0.3s ease ease !important;
  -webkit-transition: all 0.3s ease !important;
  -moz-transition: all 0.3s ease !important;
  -ms-transition: all 0.3s ease !important;
  -o-transition: all 0.3s ease !important;
  transition: all 0.3s ease !important;
  -webkit-backface-visibility: hidden !important;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden !important;
  float: inherit !important;
  margin: 0 auto !important;
  width: 53% !important;
  left: 25% !important;
  right: 25% !important;
  text-align: center !important;
}
a.typeform-share.button:hover,
a.typeform-share.button.current {
  border: 2px solid #0b787e !important;
  background-color: #0b787e !important;
}
@media screen and (max-width: 555px) {
  a.typeform-share.button {
    width: 75% !important;
    left: 11% !important;
  }
}
.btn {
  display: inline-block;
  padding: 12px 25px;
  text-decoration: none;
  border-radius: 3px;
  transition: all .4s ease;
  text-transform: uppercase;
  font-size: 14px;
  font-family: 'kanit-semibold';
  font-weight: 400;
  letter-spacing: 1px;
  cursor: pointer;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.btn-medium {
  color: white;
  background-color: #15c6bc;
  border: 2px solid #15c6bc;
}
.btn-medium:hover,
.btn-medium.current {
  border: 2px solid #12aaa2;
  background-color: #12aaa2;
}
.btn-blue {
  color: white;
  background-color: #3eb1b7;
  border: 2px solid #3eb1b7;
}
.btn-blue:hover,
.btn-blue.current {
  border: 2px solid #369ba0;
  background-color: #369ba0;
}
.btn-dark {
  color: white;
  background-color: #0e939a;
  border: 2px solid #0e939a;
}
.btn-dark:hover,
.btn-dark.current {
  border: 2px solid #0b787e;
  background-color: #0b787e;
}
.btn-white {
  color: #0e939a;
  background-color: #fff;
  border: 2px solid #fff;
}
.btn-white:hover,
.btn-white.current {
  color: white;
  background-color: transparent;
}
.btn-line {
  font-weight: 400;
  font-family: 'kanit-medium';
  border: 2px solid #322d32;
  padding: 12px 25px;
}
.btn-line:hover,
.btn-line.current {
  color: white;
  background-color: #322d32;
}
.btn-line.white {
  color: white;
  border-color: white;
}
.btn-line.white:hover,
.btn-line.white.current {
  background-color: white;
  color: #4f4d50;
}
.btn-more {
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 13px;
  line-height: 18px;
  position: relative;
  padding-right: 45px;
}
.btn-more:after {
  font-family: 'osiris';
  content: "\e90c";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 10px;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.btn-more:hover,
.btn-more.current {
  color: #0e939a;
}
.btn-more:hover:after,
.btn-more.current:after {
  right: 0;
}
.btn-base {
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  line-height: 1.1;
  border-radius: 24px;
  text-transform: uppercase;
  background-color: transparent;
  padding: 20px 30px 16px;
}
.btn-orange {
  /* Chrome, Safari, Opera */
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  line-height: 1.1;
  border-radius: 24px;
  text-transform: uppercase;
  background-color: transparent;
  padding: 20px 30px 16px;
  color: #fe5a3a;
  border: 1px solid rgba(255, 42, 0, 0.7);
}
.btn-orange:hover,
.btn-orange:focus {
  text-decoration: none;
  background-color: rgba(255, 42, 0, 0.7);
  color: #ffffff;
  border: 1px solid transparent;
}
/*!** _elements/_forms.less - Fichier destiné aux styles des forms ***/
/************** INPUTS ***************/
input,
textarea,
select {
  text-align: center;
  padding: 0 10px;
}
input:hover,
textarea:hover,
select:hover,
input:focus,
textarea:focus,
select:focus {
  outline-offset: 0;
  outline: none;
  text-shadow: none;
  box-shadow: none;
}
textarea {
  min-height: 100px;
  margin-top: 20px;
  display: block;
  border: 0;
  width: 50%;
  border-bottom: 1px solid #e4e4e4;
}
label {
  font-weight: 400;
  font-family: 'kanit-medium';
  text-align: left;
}
input[type="text"],
input[type="tel"],
input[type="number"],
input[type="email"],
textarea,
select {
  text-align: left;
  height: 32px;
  background-color: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid #2d2c2a;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
input[type="text"]:hover,
input[type="tel"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
textarea:hover,
select:hover,
input[type="text"]:focus,
input[type="tel"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
textarea:focus,
select:focus {
  border-color: #0e939a;
}
input[type="text"].input,
input[type="tel"].input,
input[type="number"].input,
input[type="email"].input,
textarea.input,
select.input {
  padding: 18px 25px;
  border: 1px solid #fff;
  color: #fff;
}
input[type="text"].input::-webkit-input-placeholder,
input[type="tel"].input::-webkit-input-placeholder,
input[type="number"].input::-webkit-input-placeholder,
input[type="email"].input::-webkit-input-placeholder,
textarea.input::-webkit-input-placeholder,
select.input::-webkit-input-placeholder {
  color: #fff;
}
input[type="text"].input:-moz-placeholder,
input[type="tel"].input:-moz-placeholder,
input[type="number"].input:-moz-placeholder,
input[type="email"].input:-moz-placeholder,
textarea.input:-moz-placeholder,
select.input:-moz-placeholder {
  /* Firefox 18- */
  color: #fff;
}
input[type="text"].input::-moz-placeholder,
input[type="tel"].input::-moz-placeholder,
input[type="number"].input::-moz-placeholder,
input[type="email"].input::-moz-placeholder,
textarea.input::-moz-placeholder,
select.input::-moz-placeholder {
  /* Firefox 19+ */
  color: #fff;
}
input[type="text"].input:-ms-input-placeholder,
input[type="tel"].input:-ms-input-placeholder,
input[type="number"].input:-ms-input-placeholder,
input[type="email"].input:-ms-input-placeholder,
textarea.input:-ms-input-placeholder,
select.input:-ms-input-placeholder {
  color: #fff;
}
input[type="text"].input-grey,
input[type="tel"].input-grey,
input[type="number"].input-grey,
input[type="email"].input-grey,
textarea.input-grey,
select.input-grey {
  background-color: #f1f1f1;
}
input[type="text"].input-dark-blue,
input[type="tel"].input-dark-blue,
input[type="number"].input-dark-blue,
input[type="email"].input-dark-blue,
textarea.input-dark-blue,
select.input-dark-blue {
  background-color: #0e939a;
}
input[type="text"].input-light-blue,
input[type="tel"].input-light-blue,
input[type="number"].input-light-blue,
input[type="email"].input-light-blue,
textarea.input-light-blue,
select.input-light-blue {
  background-color: #d2f0ee;
}
input[type="text"].input-medium-blue,
input[type="tel"].input-medium-blue,
input[type="number"].input-medium-blue,
input[type="email"].input-medium-blue,
textarea.input-medium-blue,
select.input-medium-blue {
  background-color: #15c6bc;
}
input[type="text"].input-dark,
input[type="tel"].input-dark,
input[type="number"].input-dark,
input[type="email"].input-dark,
textarea.input-dark,
select.input-dark {
  background-color: #4f4d50;
}
input[type="text"].input-pattern,
input[type="tel"].input-pattern,
input[type="number"].input-pattern,
input[type="email"].input-pattern,
textarea.input-pattern,
select.input-pattern {
  background-image: url('../images/pattern-white.png');
  background-size: 130px;
}
input[type="text"].input-large-pattern,
input[type="tel"].input-large-pattern,
input[type="number"].input-large-pattern,
input[type="email"].input-large-pattern,
textarea.input-large-pattern,
select.input-large-pattern {
  background-image: url('../images/pattern-blue.png');
  background-size: 190px;
}
select::-ms-expand {
  display: none;
}
.input-select {
  display: inline-block;
  position: relative;
}
.input-select select {
  padding-right: 30px;
}
.input-select:hover:before {
  color: #0e939a;
}
.input-select:before {
  display: block;
  position: absolute;
  font-family: 'osiris';
  content: "\e908";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  font-size: 10px;
  right: 10px;
  top: 17px;
  z-index: 1;
}
/************** FLAT FORM ***************/
@media (max-width: 992px) {
  .top.navigation .flatform p {
    width: 100%;
  }
}
@media (max-width: 650px) {
  .top.navigation .flatform p .flatform-label {
    display: block;
  }
  .top.navigation .flatform p .flatform-input {
    width: 100%;
  }
  .top.navigation .flatform p .flatform-input input[type="number"] {
    width: 80%;
  }
}
.flatform {
  text-align: left;
  display: inline-block;
  font-size: 0;
  margin-top: 45px;
  padding: 10px 15px;
  border-radius: 3px;
  background-color: white;
  position: relative;
}
@media (max-width: 650px) {
  .flatform {
    width: 80%;
    padding: 15px 10px;
  }
}
@media (max-width: 450px) {
  .flatform {
    width: 100%;
  }
}
.flatform:before,
.flatform:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  font-family: 'osiris';
  content: "\e902";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  height: 100%;
  color: white;
  font-size: 30px;
  height: 30px;
}
@media (max-width: 650px) {
  .flatform:before,
  .flatform:after {
    content: none;
  }
}
.flatform:before {
  left: -70px;
}
.flatform:after {
  right: -70px;
}
.flatform .input-select:before {
  top: 12px;
}
.flatform p {
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  border-left: 1px solid #E3E3E3;
  padding: 0 15px;
}
.flatform p:first-child,
.flatform p:last-child {
  border: 0;
}
.flatform p.flatform-country {
  border-left: 0;
}
@media (max-width: 992px) {
  .flatform p {
    width: 50%;
    margin: 5px 0;
    border-left: 0;
  }
}
@media (max-width: 650px) {
  .flatform p {
    width: 100%;
    border: 0;
  }
}
@media (max-width: 992px) and (min-width: 650px) {
  .flatform p.flatform-max {
    border-left: 0;
    border-right: 0;
  }
}
.flatform-country,
.flatform-estate {
  width: 17%;
}
.flatform-bedroom {
  width: 20%;
}
.flatform-max {
  width: 24%;
}
@media (max-width: 1200px) {
  .flatform-max {
    width: 29%;
  }
}
.flatform-submit {
  text-align: center;
  width: 17%;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 1200px) {
  .flatform-submit {
    width: 15%;
    padding: 10px 15px;
    font-size: 13px;
    line-height: 18px;
  }
}
@media (max-width: 992px) {
  .flatform-submit {
    width: 30%;
  }
}
.flatform-submit input {
  display: inline-block;
  width: 100%;
}
@media (max-width: 1200px) {
  .flatform-submit input {
    padding: 10px 15px;
    font-size: 10px;
    line-height: 15px;
  }
}
@media (max-width: 992px) {
  .flatform-submit input {
    width: 30%;
    font-size: 13px;
    line-height: 18px;
  }
}
@media (max-width: 650px) {
  .flatform-submit input {
    width: 50%;
    margin-bottom: 0;
    margin-top: 10px;
  }
}
.flatform-label {
  font-size: initial;
  display: inline-block;
  padding-right: 4%;
}
@media (max-width: 992px) {
  .flatform-label {
    width: 20%;
  }
}
@media (max-width: 650px) {
  .flatform-label {
    width: 100%;
    display: block;
    margin: 0 0 4px;
  }
}
.flatform-input {
  width: 100%;
}
.flatform select {
  width: 100%;
}
.flatform-currency {
  font-size: 12px;
  padding-left: 3%;
}
.input-budget {
  display: block;
  width: 100%;
}
.input-budget input[type="number"] {
  width: 66%;
}
@media (max-width: 650px) {
  .input-budget input[type="number"] {
    width: 80%;
  }
}
/************** BOUTON VALIDER ***************/
input[type="submit"],
button[type="submit"] {
  border: 0;
  padding: 10px 15px;
  cursor: pointer;
}
input[type="submit"]:hover,
button[type="submit"]:hover,
input[type="submit"]:focus,
button[type="submit"]:focus {
  border: 0;
}
/************** MESSAGES D'ERREUR ***************/
/************** LANDING PAGE ***************/
.landing-page .contact-form {
  padding: 30px 0 0;
}
.landing-page .form-block {
  width: 50%;
  display: inline-block;
  margin: 0 0 0 -4px;
  vertical-align: top;
}
.landing-page .form-block.spec {
  padding: 0 2.5% 0 3.5%;
}
@media (max-width: 480px) {
  .landing-page .form-block {
    width: 100%;
    display: block;
    margin: 0 0 0;
  }
}
.landing-page .form-block br {
  display: none;
}
.landing-page input[type=email],
.landing-page input[type=text],
.landing-page input[type=tel],
.landing-page input[type=month],
.landing-page select {
  height: 44px;
  width: 90%;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid rgba(255, 255, 255, 0.75);
  color: #ffffff;
  margin: 0 0 10px;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.landing-page input[type=email]:hover,
.landing-page input[type=text]:hover,
.landing-page input[type=tel]:hover,
.landing-page input[type=month]:hover,
.landing-page select:hover,
.landing-page input[type=email]:focus,
.landing-page input[type=text]:focus,
.landing-page input[type=tel]:focus,
.landing-page input[type=month]:focus,
.landing-page select:focus {
  border-color: #ffffff;
}
.landing-page input[type=email]::-webkit-input-placeholder,
.landing-page input[type=text]::-webkit-input-placeholder,
.landing-page input[type=tel]::-webkit-input-placeholder,
.landing-page input[type=month]::-webkit-input-placeholder,
.landing-page select::-webkit-input-placeholder {
  color: #ffffff;
}
.landing-page input[type=email]:-moz-placeholder,
.landing-page input[type=text]:-moz-placeholder,
.landing-page input[type=tel]:-moz-placeholder,
.landing-page input[type=month]:-moz-placeholder,
.landing-page select:-moz-placeholder {
  /* Firefox 18- */
  color: #ffffff;
}
.landing-page input[type=email]::-moz-placeholder,
.landing-page input[type=text]::-moz-placeholder,
.landing-page input[type=tel]::-moz-placeholder,
.landing-page input[type=month]::-moz-placeholder,
.landing-page select::-moz-placeholder {
  /* Firefox 19+ */
  color: #ffffff;
}
.landing-page input[type=email]:-ms-input-placeholder,
.landing-page input[type=text]:-ms-input-placeholder,
.landing-page input[type=tel]:-ms-input-placeholder,
.landing-page input[type=month]:-ms-input-placeholder,
.landing-page select:-ms-input-placeholder {
  color: #ffffff;
}
.landing-page input[type=email] option,
.landing-page input[type=text] option,
.landing-page input[type=tel] option,
.landing-page input[type=month] option,
.landing-page select option {
  background-color: #ffffff;
}
.landing-page input[type=date] {
  height: 44px;
  width: 90%;
  background-color: #ffffff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid rgba(45, 44, 43, 0.5);
  color: #2d2c2b;
  margin: 0 0 10px;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.landing-page input[type=date]:hover,
.landing-page input[type=date]:focus {
  border-color: #2d2c2b;
}
.landing-page input[type=date]::-webkit-input-placeholder {
  color: #2d2c2b;
}
.landing-page input[type=date]:-moz-placeholder {
  /* Firefox 18- */
  color: #2d2c2b;
}
.landing-page input[type=date]::-moz-placeholder {
  /* Firefox 19+ */
  color: #2d2c2b;
}
.landing-page input[type=date]:-ms-input-placeholder {
  color: #2d2c2b;
}
.landing-page input[type=submit] {
  margin: 30px 0 0;
  background-color: #ffffff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 15px 30px;
  border: 1px solid #ffffff;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 15px;
  line-height: 20px;
  line-height: 15px;
  color: #0e939a;
  text-transform: uppercase;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.landing-page input[type=submit]:hover,
.landing-page input[type=submit]:focus {
  background-color: transparent;
  color: #ffffff;
}
.landing-page select {
  width: 100%;
}
.landing-page .input-select {
  display: inline-block;
  position: relative;
  width: 90%;
}
.landing-page .input-select:before {
  position: absolute;
  font-family: 'osiris';
  content: "\e908";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  font-size: 10px;
  line-height: 15px;
  line-height: 10px;
  right: 10px;
  top: 17px;
  height: 10px;
  color: #ffffff;
}
.contact-page {
  text-align: center;
  padding: 30px 0 0;
}
.contact-page .form-block {
  width: 50%;
  display: inline-block;
  margin: 0 0 0 -4px;
  vertical-align: top;
}
.contact-page .form-block.spec {
  padding: 0 2.5% 0 3.5%;
}
@media (max-width: 480px) {
  .contact-page .form-block {
    width: 100%;
    display: block;
    margin: 0 0 0;
  }
}
.contact-page input[type=email],
.contact-page input[type=text],
.contact-page input[type=tel],
.contact-page select {
  height: 44px;
  width: 90%;
  background-color: #ffffff;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid rgba(45, 44, 43, 0.5);
  color: #2d2c2b;
  margin: 0 0 10px;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.contact-page input[type=email]:hover,
.contact-page input[type=text]:hover,
.contact-page input[type=tel]:hover,
.contact-page select:hover,
.contact-page input[type=email]:focus,
.contact-page input[type=text]:focus,
.contact-page input[type=tel]:focus,
.contact-page select:focus {
  border-color: #2d2c2b;
}
.contact-page input[type=email]::-webkit-input-placeholder,
.contact-page input[type=text]::-webkit-input-placeholder,
.contact-page input[type=tel]::-webkit-input-placeholder,
.contact-page select::-webkit-input-placeholder {
  color: #2d2c2b;
}
.contact-page input[type=email]:-moz-placeholder,
.contact-page input[type=text]:-moz-placeholder,
.contact-page input[type=tel]:-moz-placeholder,
.contact-page select:-moz-placeholder {
  /* Firefox 18- */
  color: #2d2c2b;
}
.contact-page input[type=email]::-moz-placeholder,
.contact-page input[type=text]::-moz-placeholder,
.contact-page input[type=tel]::-moz-placeholder,
.contact-page select::-moz-placeholder {
  /* Firefox 19+ */
  color: #2d2c2b;
}
.contact-page input[type=email]:-ms-input-placeholder,
.contact-page input[type=text]:-ms-input-placeholder,
.contact-page input[type=tel]:-ms-input-placeholder,
.contact-page select:-ms-input-placeholder {
  color: #2d2c2b;
}
.contact-page input[type=date] {
  height: 44px;
  width: 90%;
  background-color: #ffffff;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 16px;
  line-height: 21px;
  border: 1px solid rgba(45, 44, 43, 0.5);
  color: #2d2c2b;
  margin: 0 0 10px;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.contact-page input[type=date]:hover,
.contact-page input[type=date]:focus {
  border-color: #2d2c2b;
}
.contact-page input[type=date]::-webkit-input-placeholder {
  color: #2d2c2b;
}
.contact-page input[type=date]:-moz-placeholder {
  /* Firefox 18- */
  color: #2d2c2b;
}
.contact-page input[type=date]::-moz-placeholder {
  /* Firefox 19+ */
  color: #2d2c2b;
}
.contact-page input[type=date]:-ms-input-placeholder {
  color: #2d2c2b;
}
.contact-page textarea {
  width: 95.6%;
  margin: 0 auto;
  border: 1px solid rgba(45, 44, 43, 0.5);
}
.contact-page textarea:hover,
.contact-page textarea:focus {
  border-color: #2d2c2b;
}
.contact-page textarea::-webkit-input-placeholder {
  color: #2d2c2b;
}
.contact-page textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #2d2c2b;
}
.contact-page textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #2d2c2b;
}
.contact-page textarea:-ms-input-placeholder {
  color: #2d2c2b;
}
.contact-page select {
  width: 100%;
}
.contact-page .input-select {
  display: inline-block;
  position: relative;
  width: 90%;
}
.contact-page .input-select:before {
  position: absolute;
  font-family: 'osiris';
  content: "\e908";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  font-size: 10px;
  line-height: 15px;
  line-height: 10px;
  right: 10px;
  top: 17px;
  display: block;
}
.contact-page .btn-icon {
  position: relative;
}
.contact-page .btn-icon input[type="submit"] {
  padding: 15px 30px 15px 45px;
}
.contact-page .btn-icon:before {
  font-family: 'osiris';
  content: "\e90d";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #ffffff;
  font-size: 14px;
  line-height: 19px;
  line-height: 14px;
  position: absolute;
  left: 15px;
  top: 50%;
  margin-top: -7px;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.contact-page .btn-icon:hover:before {
  color: #0e939a;
}
.contact-page input[type=submit] {
  margin: 30px 0 0;
  background-color: #0e939a;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  padding: 15px 30px;
  border: 1px solid #0e939a;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 15px;
  line-height: 20px;
  line-height: 15px;
  color: #ffffff;
  text-transform: uppercase;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.contact-page input[type=submit]:hover,
.contact-page input[type=submit]:focus {
  background-color: transparent;
  color: #0e939a;
}
.contact-page p {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  margin: 0 0 25px;
}
div#ui-datepicker-div {
  margin-top: -145px;
}
.wpcf7-not-valid {
  border-color: #f00 !important;
}
.wpcf7-validates-as-required {
  border-color: #0e939a !important;
}
span.wpcf7-fake {
  height: 54px;
}
div.wpcf7-validation-errors {
  text-align: center;
  background-color: #3eb1b7;
  border: none !important;
  color: #fff;
  font-size: 18px;
  line-height: 23px;
  font-family: 'kanit-light';
  font-weight: 100;
}
div.wpcf7-mail-sent-ok {
  text-align: center;
  background-color: #15c6bc;
  border: none !important;
  color: #fff;
  font-size: 18px;
  line-height: 23px;
  font-family: 'kanit-light';
  font-weight: 100;
}
.input-hidden {
  display: none;
}
.block-blue.blue-bg.landing-page .label-tiny label {
  color: #fff;
}
.label-tiny {
  text-align: left;
  margin-left: 25px;
  margin-bottom: 5px;
}
.form-block span {
  display: block;
}
.form-block p {
  margin: 0;
}
.form-block br {
  display: none;
}
.wrapper-filter {
  display: block;
  text-align: left;
}
span[role="alert"] {
  display: block;
  margin-bottom: 10px;
}
/******** recaptcha 2018 ************/
.wpcf7-recaptcha {
  display: inline-block;
}
.section {
  padding: 50px 0;
}
.section-grey {
  background-color: #f1f1f1;
}
.section-dark-blue {
  background-color: #0e939a;
}
.section-medium-dark-blue {
  background-color: #3eb1b7;
}
.section-medium-blue {
  background-color: #15c6bc;
}
.section-contact-blue {
  background-color: #40b3b8;
}
.section-light-blue {
  background-color: #d2f0ee;
}
.section-dark {
  background-color: #4f4d50;
}
.section-pattern {
  background-image: url('../images/pattern-white.png');
  background-size: 130px;
}
.section-large-pattern {
  background-image: url('../images/pattern-blue.png');
  background-size: 190px;
}
.row-with-pattern .wpb_wrapper {
  background-image: url('../images/pattern-white.png');
  background-size: 130px;
  background-color: #d2f0ee;
}
.row-with-bg-contact > .wpb_column > .vc_column-inner > .wpb_wrapper {
  background-image: url('../images/bg-contact.jpg');
  background-color: #f2f2f2;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: 1330px;
}
.row-with-bg-grey .wpb_wrapper {
  background-color: #f1f1f1;
}
.row-with-bg-dark-blue .wpb_wrapper {
  background-color: #0e939a;
}
.row-with-bg-medium-dark-blue .wpb_wrapper {
  background-color: #3eb1b7;
}
.row-with-bg-medium-blue .wpb_wrapper {
  background-color: #15c6bc;
}
.row-with-bg-light-blue .wpb_wrapper {
  background-color: #d2f0ee;
}
.row-with-bg-dark .wpb_wrapper {
  background-color: #4f4d50;
}
.row-with-bg-pattern .wpb_wrapper {
  background-image: url('../images/pattern-white.png');
  background-size: 130px;
}
.row-with-bg-large-pattern .wpb_wrapper {
  background-image: url('../images/pattern-blue.png');
  background-size: 190px;
}
section {
  padding-top: 40px;
  padding-bottom: 40px;
}
/*!** _elements/_edito.less - Fichier destiné aux styles edito ***/
/************** TEXTES ******/
.text p {
  line-height: 24px;
}
.text ol li:before {
  display: none;
}
.text ul li {
  list-style-type: none;
  position: relative;
  padding: 3px 0;
}
.text ul li:before {
  content: '';
  background-size: cover;
  width: 10px;
  height: 10px;
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  margin: auto;
}
.text em,
.text i {
  font-style: italic;
}
.text hr {
  color: #2d2c2a;
  max-width: 80%;
}
.text a {
  font-family: 'kanit-bold';
  font-weight: 900;
  display: inline-block;
  vertical-align: bottom;
}
.edito-content {
  background-color: #e3e3e3;
  padding: 30px 200px;
}
@media (max-width: 1200px) {
  .edito-content {
    padding: 30px 0;
  }
}
.landing-page {
  display: inline-block;
  display: block;
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #322d32;
  margin: 0 0 10px;
}
.landing-page:before,
.landing-page:after {
  content: " ";
  display: table;
}
.landing-page:after {
  clear: both;
}
@media (max-width: 768px) {
  .landing-page {
    padding: 30px 20px;
  }
}
.landing-page p {
  display: inline-block;
  display: block;
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #322d32;
  margin: 0 0 10px;
}
.landing-page p:before,
.landing-page p:after {
  content: " ";
  display: table;
}
.landing-page p:after {
  clear: both;
}
.landing-page p a {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #0e939a;
  text-decoration: underline;
}
.landing-page p a:hover {
  text-decoration: none;
}
.landing-page p strong {
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}
.landing-page p strong a {
  font-family: 'kanit-regular';
  font-weight: 400;
}
.landing-page em {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
}
.landing-page.trans-bg {
  background-color: transparent;
  padding: 30px 6%;
  margin: 0;
}
.landing-page.white-bg {
  background-color: #ffffff;
  padding: 30px 6%;
  margin: 0 0 40px;
}
.landing-page.blue-bg {
  background-color: #0e939a;
  padding: 35px 60px;
  text-align: center;
  margin: 0 0 40px;
}
.landing-page.blue-bg p {
  color: #ffffff;
}
.landing-page.blue-bg p.text-blue {
  color: #15f9ec;
}
.landing-page ul {
  margin: 0 0 10px;
  list-style: none;
}
.landing-page ul li {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #322d32;
  margin: 0 0 10px;
  padding: 0 0 0 15px;
  position: relative;
}
.landing-page ul li:before {
  content: "• ";
  color: #0e939a;
  position: absolute;
  left: 0;
}
.landing-page ul li strong {
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}
.landing-page img.alignright {
  margin-left: 30px;
  float: right;
}
@media (max-width: 768px) {
  .landing-page img.alignright {
    float: none;
    display: block;
    margin: 0 auto 20px;
  }
}
.landing-page img.alignleft {
  margin-right: 30px;
  float: left;
}
@media (max-width: 768px) {
  .landing-page img.alignleft {
    float: none;
    display: block;
    margin: 0 auto 20px;
  }
}
.landing-page img.aligncenter {
  display: block;
  margin: 0 auto 20px;
}
.map-text p {
  text-align: center;
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 15px;
  line-height: 20px;
  color: #2d2c2b;
  margin: 0 0 50px;
}
.map-text p strong {
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 15px;
  line-height: 20px;
  color: #2d2c2b;
}
.wpb_wrapper .wpb_text_column,
.news-content-text {
  display: inline-block;
  display: block;
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #322d32;
  margin: 0 0 10px;
}
.wpb_wrapper .wpb_text_column h1,
.news-content-text h1 {
  color: #0e939a;
}
.wpb_wrapper .wpb_text_column h2,
.news-content-text h2 {
  color: #0e939a;
  font-size: 32px;
  line-height: 37px;
  font-family: 'kanit-extralight';
  font-weight: 100;
  margin-bottom: 15px;
  margin-top: 10px;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column h2,
  .news-content-text h2 {
    font-size: 30px;
    line-height: 35px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column h2,
  .news-content-text h2 {
    font-size: 28px;
    line-height: 33px;
  }
}
@media (max-width: 450px) {
  .wpb_wrapper .wpb_text_column h2,
  .news-content-text h2 {
    font-size: 26px;
    line-height: 31px;
  }
}
.wpb_wrapper .wpb_text_column h3,
.news-content-text h3 {
  color: #0e939a;
  font-size: 24px;
  line-height: 29px;
  font-family: 'kanit-extralight';
  font-weight: 100;
  margin-bottom: 10px;
  margin-top: 10px;
  display: inline-block;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column h3,
  .news-content-text h3 {
    font-size: 21px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column h3,
  .news-content-text h3 {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 450px) {
  .wpb_wrapper .wpb_text_column h3,
  .news-content-text h3 {
    font-size: 18px;
    line-height: 23px;
  }
}
.wpb_wrapper .wpb_text_column h4,
.news-content-text h4 {
  color: #0e939a;
  font-size: 22px;
  line-height: 27px;
  font-family: 'kanit-extralight';
  font-weight: 100;
  margin-bottom: 10px;
  margin-top: 10px;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column h4,
  .news-content-text h4 {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column h4,
  .news-content-text h4 {
    font-size: 18px;
    line-height: 23px;
  }
}
@media (max-width: 450px) {
  .wpb_wrapper .wpb_text_column h4,
  .news-content-text h4 {
    font-size: 16px;
    line-height: 21px;
  }
}
.wpb_wrapper .wpb_text_column h5,
.news-content-text h5 {
  color: #0e939a;
  font-size: 25px;
  line-height: 30px;
  font-family: 'kanit-extralight';
  font-weight: 100;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column h5,
  .news-content-text h5 {
    font-size: 21px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column h5,
  .news-content-text h5 {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 450px) {
  .wpb_wrapper .wpb_text_column h5,
  .news-content-text h5 {
    font-size: 18px;
    line-height: 23px;
  }
}
.wpb_wrapper .wpb_text_column h6,
.news-content-text h6 {
  color: #0e939a;
  font-size: 25px;
  line-height: 30px;
  font-family: 'kanit-extralight';
  font-weight: 100;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column h6,
  .news-content-text h6 {
    font-size: 21px;
    line-height: 26px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column h6,
  .news-content-text h6 {
    font-size: 20px;
    line-height: 25px;
  }
}
@media (max-width: 450px) {
  .wpb_wrapper .wpb_text_column h6,
  .news-content-text h6 {
    font-size: 18px;
    line-height: 23px;
  }
}
.wpb_wrapper .wpb_text_column:before,
.news-content-text:before,
.wpb_wrapper .wpb_text_column:after,
.news-content-text:after {
  content: " ";
  display: table;
}
.wpb_wrapper .wpb_text_column:after,
.news-content-text:after {
  clear: both;
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column,
  .news-content-text {
    padding: 30px 20px;
  }
}
.wpb_wrapper .wpb_text_column a,
.news-content-text a {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #0e939a;
  text-decoration: underline;
}
.wpb_wrapper .wpb_text_column a:hover,
.news-content-text a:hover {
  text-decoration: none;
}
.wpb_wrapper .wpb_text_column p,
.news-content-text p {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #322d32;
  margin: 0 0 10px;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column p,
  .news-content-text p {
    font-size: 17px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column p,
  .news-content-text p {
    font-size: 16px;
    line-height: 21px;
    line-height: 20px;
  }
}
@media (max-width: 480px) {
  .wpb_wrapper .wpb_text_column p,
  .news-content-text p {
    font-size: 15px;
    line-height: 20px;
    line-height: 18px;
  }
}
.wpb_wrapper .wpb_text_column p a,
.news-content-text p a {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #0e939a;
  text-decoration: underline;
}
.wpb_wrapper .wpb_text_column p a img,
.news-content-text p a img {
  position: relative;
  z-index: 10;
}
@media (max-width: 350px) {
  .wpb_wrapper .wpb_text_column p a img,
  .news-content-text p a img {
    max-width: 100%;
    height: auto;
    display: block;
  }
}
.wpb_wrapper .wpb_text_column p a:hover,
.news-content-text p a:hover {
  text-decoration: none;
}
.wpb_wrapper .wpb_text_column p strong,
.news-content-text p strong {
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}
.wpb_wrapper .wpb_text_column p strong a,
.news-content-text p strong a {
  font-family: 'kanit-regular';
  font-weight: 400;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column p strong,
  .news-content-text p strong {
    font-size: 17px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column p strong,
  .news-content-text p strong {
    font-size: 16px;
    line-height: 21px;
    line-height: 20px;
  }
}
@media (max-width: 480px) {
  .wpb_wrapper .wpb_text_column p strong,
  .news-content-text p strong {
    font-size: 15px;
    line-height: 20px;
    line-height: 18px;
  }
}
.wpb_wrapper .wpb_text_column.trans-bg,
.news-content-text.trans-bg {
  background-color: transparent;
  padding: 30px 6%;
  margin: 0;
}
.wpb_wrapper .wpb_text_column.white-bg,
.news-content-text.white-bg {
  background-color: #ffffff;
  padding: 30px 6%;
  margin: 0 0 40px;
}
.wpb_wrapper .wpb_text_column.blue-bg,
.news-content-text.blue-bg {
  background-color: #0e939a;
  padding: 35px 60px;
  text-align: center;
  margin: 0 0 40px;
}
.wpb_wrapper .wpb_text_column.blue-bg p,
.news-content-text.blue-bg p {
  color: #ffffff;
}
.wpb_wrapper .wpb_text_column.blue-bg p.text-blue,
.news-content-text.blue-bg p.text-blue {
  color: #15f9ec;
}
.wpb_wrapper .wpb_text_column ul,
.news-content-text ul {
  margin: 0 0 20px;
  list-style: none;
  display: table;
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column ul,
  .news-content-text ul {
    padding-left: 10px;
  }
}
.wpb_wrapper .wpb_text_column ul li,
.news-content-text ul li {
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 19px;
  line-height: 24px;
  color: #322d32;
  margin: 0 0 10px;
  padding: 0 0 0 20px;
  position: relative;
}
.wpb_wrapper .wpb_text_column ul li:before,
.news-content-text ul li:before {
  content: "• ";
  color: #0e939a;
  position: absolute;
  left: 0;
}
.wpb_wrapper .wpb_text_column ul li strong,
.news-content-text ul li strong {
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column ul li strong,
  .news-content-text ul li strong {
    font-size: 17px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column ul li strong,
  .news-content-text ul li strong {
    font-size: 16px;
    line-height: 21px;
    line-height: 20px;
  }
}
@media (max-width: 480px) {
  .wpb_wrapper .wpb_text_column ul li strong,
  .news-content-text ul li strong {
    font-size: 15px;
    line-height: 20px;
    line-height: 18px;
  }
}
@media (max-width: 992px) {
  .wpb_wrapper .wpb_text_column ul li,
  .news-content-text ul li {
    font-size: 17px;
    line-height: 22px;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column ul li,
  .news-content-text ul li {
    font-size: 16px;
    line-height: 21px;
    line-height: 20px;
  }
}
@media (max-width: 480px) {
  .wpb_wrapper .wpb_text_column ul li,
  .news-content-text ul li {
    font-size: 15px;
    line-height: 20px;
    line-height: 18px;
  }
}
.wpb_wrapper .wpb_text_column figcaption,
.news-content-text figcaption {
  display: inherit;
}
.wpb_wrapper .wpb_text_column blockquote,
.news-content-text blockquote {
  font-style: italic;
}
.wpb_wrapper .wpb_text_column figure.alignright,
.news-content-text figure.alignright {
  margin: 10px 0 20px 30px;
  float: right;
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column figure.alignright,
  .news-content-text figure.alignright {
    margin-left: 0;
    float: none;
    display: block;
    margin: 0 auto 20px;
  }
}
.wpb_wrapper .wpb_text_column figure.alignleft,
.news-content-text figure.alignleft {
  margin: 0 30px 20px 0;
  float: left;
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column figure.alignleft,
  .news-content-text figure.alignleft {
    margin-right: 0;
    float: none;
    display: block;
    margin: 0 auto 20px;
  }
}
.wpb_wrapper .wpb_text_column figure.aligncenter,
.news-content-text figure.aligncenter {
  display: block;
  margin: 0 auto 20px;
}
.wpb_wrapper .wpb_text_column img.alignright,
.news-content-text img.alignright {
  margin: 10px 0 20px 30px;
  float: right;
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column img.alignright,
  .news-content-text img.alignright {
    margin-left: 0;
    float: none;
    display: block;
    margin: 0 auto 20px;
  }
}
.wpb_wrapper .wpb_text_column img.alignleft,
.news-content-text img.alignleft {
  margin: 0 30px 20px 0;
  float: left;
}
@media (max-width: 768px) {
  .wpb_wrapper .wpb_text_column img.alignleft,
  .news-content-text img.alignleft {
    margin-right: 0;
    float: none;
    display: block;
    margin: 0 auto 20px;
  }
}
.wpb_wrapper .wpb_text_column img.aligncenter,
.news-content-text img.aligncenter {
  display: block;
  margin: 0 auto 20px;
}
.wpb_wrapper .wpb_text_column table,
.news-content-text table {
  border-collapse: collapse;
}
.wpb_wrapper .wpb_text_column table,
.news-content-text table,
.wpb_wrapper .wpb_text_column th,
.news-content-text th,
.wpb_wrapper .wpb_text_column td,
.news-content-text td {
  border: 1px solid #000;
}
.wpb_wrapper .wpb_text_column table th,
.news-content-text table th {
  background: #3eb1b7;
}
.news-content-text h2 {
  margin-top: 30px;
}
.news-content-text h3 {
  margin-bottom: 15px;
}
.news-content-text h4 {
  margin-top: 25px;
}
.plan-du-site .wpb_wrapper .wpb_text_column ul {
  padding: 0;
  margin: 0;
}
.plan-du-site .wpb_wrapper .wpb_text_column li {
  margin: 0 0 5px;
  padding: 0;
}
.plan-du-site .wpb_wrapper .wpb_text_column li:before {
  content: "";
}
.plan-du-site .wpb_wrapper .wpb_text_column li ul.children {
  padding: 0 0 0 10px;
}
.plan-du-site .wpb_wrapper .wpb_text_column li ul.children a {
  font-size: 17px;
  line-height: 22px;
  line-height: 29px;
}
.plan-du-site .wpb_wrapper .wpb_text_column li ul.children li ul.children {
  padding: 0 0 0 10px;
}
.plan-du-site .wpb_wrapper .wpb_text_column li ul.children li ul.children li {
  margin: 0 0 2px;
}
.plan-du-site .wpb_wrapper .wpb_text_column li ul.children li ul.children li:before {
  content: "";
}
.plan-du-site .wpb_wrapper .wpb_text_column li ul.children li ul.children a {
  font-size: 16px;
  line-height: 21px;
  line-height: 18px;
}
.plan-du-site .wpb_wrapper .wpb_text_column a {
  text-decoration: none;
}
.plan-du-site .wpb_wrapper .wpb_text_column a:hover {
  text-decoration: underline;
}
.acf-map {
  width: 100%;
  height: 400px;
  border: #ccc solid 1px;
  margin: 40px 0 0;
}
/* fixes potential theme css conflict */
.acf-map img {
  max-width: inherit !important;
}
img {
  max-width: 100%;
  height: auto;
}
.breadcrumb {
  display: inline-block;
  padding: 10px 0;
  margin: 10px 0;
}
.breadcrumb-li {
  display: inline-block;
  vertical-align: top;
  padding: 0 10px;
  border-right: 1px solid #f1f1f1;
}
.breadcrumb-li:first-child {
  padding-left: 0;
}
.pagination {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  text-align: center;
}
.pagination-link {
  font-family: 'kanit-bold';
  font-weight: 900;
  padding: 0 10px;
}
.pagination-link:hover {
  color: #0e939a;
}
.pagination-link.current {
  color: #0e939a;
}
.pagination-link.prev {
  width: 20px;
  height: 20px;
  position: relative;
  padding-left: 15px;
}
.pagination-link.prev:before {
  font-family: 'osiris';
  content: "\e90c";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  left: 0;
  position: absolute;
  font-weight: bold;
  font-size: 20px;
}
.pagination-link.next {
  width: 20px;
  height: 20px;
  position: relative;
  padding-right: 15px;
}
.pagination-link.next:before {
  font-family: 'osiris';
  content: "\e90c";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  right: 0;
  position: absolute;
  font-weight: bold;
  font-size: 20px;
}
/************* GRID **********/
/******* gutter 60px ******/
.grid-g60-1 {
  font-size: 0;
  margin-left: -30px;
  margin-right: -30px;
}
.grid-g60-1 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 8.333333333333334%;
  padding: 0 30px;
}
.grid-g60-2 {
  font-size: 0;
  margin-left: -30px;
  margin-right: -30px;
}
.grid-g60-2 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 16.666666666666668%;
  padding: 0 30px;
}
.grid-g60-3 {
  font-size: 0;
  margin-left: -30px;
  margin-right: -30px;
}
.grid-g60-3 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 30px;
}
.grid-g60-4 {
  font-size: 0;
  margin-left: -30px;
  margin-right: -30px;
}
.grid-g60-4 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 30px;
}
.grid-g60-5 {
  font-size: 0;
  margin-left: -30px;
  margin-right: -30px;
}
.grid-g60-5 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 41.66666666666667%;
  padding: 0 30px;
}
.grid-g60-6 {
  font-size: 0;
  margin-left: -30px;
  margin-right: -30px;
}
.grid-g60-6 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 30px;
}
@media (max-width: 992px) {
  .md-grid-g60-12 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .md-grid-g60-12 .col {
    width: 100%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 992px) {
  .md-grid-g60-6 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .md-grid-g60-6 .col {
    width: 50%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 992px) {
  .md-grid-g60-4 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .md-grid-g60-4 .col {
    width: 33.333333333333336%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 992px) {
  .md-grid-g60-3 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .md-grid-g60-3 .col {
    width: 25%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g60-12 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .sm-grid-g60-12 .col {
    width: 100%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g60-6 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .sm-grid-g60-6 .col {
    width: 50%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g60-4 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .sm-grid-g60-4 .col {
    width: 33.333333333333336%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g60-3 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .sm-grid-g60-3 .col {
    width: 25%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 650px) {
  .xs-grid-g60-12 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .xs-grid-g60-12 .col {
    width: 100%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 650px) {
  .xs-grid-g60-6 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .xs-grid-g60-6 .col {
    width: 50%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
@media (max-width: 650px) {
  .xs-grid-g60-4 {
    margin-left: -30px;
    margin-right: -30px;
  }
  .xs-grid-g60-4 .col {
    width: 33.333333333333336%;
    padding: 0 30px;
    margin-bottom: 60px;
  }
}
/******* gutter 30px ******/
.grid-1 {
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.grid-1 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 8.333333333333334%;
  padding: 0 15px;
}
.grid-2 {
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.grid-2 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 16.666666666666668%;
  padding: 0 15px;
}
.grid-3 {
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.grid-3 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 15px;
}
.grid-4 {
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.grid-4 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 15px;
}
.grid-5 {
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.grid-5 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 41.66666666666667%;
  padding: 0 15px;
}
.grid-6 {
  font-size: 0;
  margin-left: -15px;
  margin-right: -15px;
}
.grid-6 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 15px;
}
@media (max-width: 992px) {
  .md-grid-12 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .md-grid-12 .col {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .md-grid-6 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .md-grid-6 .col {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .md-grid-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .md-grid-4 .col {
    width: 33.333333333333336%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 992px) {
  .md-grid-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .md-grid-3 .col {
    width: 25%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .sm-grid-12 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sm-grid-12 .col {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .sm-grid-6 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sm-grid-6 .col {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .sm-grid-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sm-grid-4 .col {
    width: 33.333333333333336%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 768px) {
  .sm-grid-3 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .sm-grid-3 .col {
    width: 25%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 650px) {
  .xs-grid-12 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .xs-grid-12 .col {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 650px) {
  .xs-grid-6 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .xs-grid-6 .col {
    width: 50%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
@media (max-width: 650px) {
  .xs-grid-4 {
    margin-left: -15px;
    margin-right: -15px;
  }
  .xs-grid-4 .col {
    width: 33.333333333333336%;
    padding: 0 15px;
    margin-bottom: 30px;
  }
}
/******* gutter 15px ******/
.grid-g15-1 {
  font-size: 0;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.grid-g15-1 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 8.333333333333334%;
  padding: 0 7.5px;
}
.grid-g15-2 {
  font-size: 0;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.grid-g15-2 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 16.666666666666668%;
  padding: 0 7.5px;
}
.grid-g15-3 {
  font-size: 0;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.grid-g15-3 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 7.5px;
}
.grid-g15-4 {
  font-size: 0;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.grid-g15-4 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 7.5px;
}
.grid-g15-5 {
  font-size: 0;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.grid-g15-5 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 41.66666666666667%;
  padding: 0 7.5px;
}
.grid-g15-6 {
  font-size: 0;
  margin-left: -7.5px;
  margin-right: -7.5px;
}
.grid-g15-6 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 7.5px;
}
@media (max-width: 1250px) {
  .lg-grid-g15-12 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .lg-grid-g15-12 .col {
    width: 100%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1250px) {
  .lg-grid-g15-6 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .lg-grid-g15-6 .col {
    width: 50%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1250px) {
  .lg-grid-g15-4 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .lg-grid-g15-4 .col {
    width: 33.333333333333336%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1250px) {
  .lg-grid-g15-3 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .lg-grid-g15-3 .col {
    width: 25%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .md-grid-g15-12 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .md-grid-g15-12 .col {
    width: 100%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .md-grid-g15-6 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .md-grid-g15-6 .col {
    width: 50%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .md-grid-g15-4 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .md-grid-g15-4 .col {
    width: 33.333333333333336%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .md-grid-g15-3 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .md-grid-g15-3 .col {
    width: 25%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g15-12 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .sm-grid-g15-12 .col {
    width: 100%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g15-6 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .sm-grid-g15-6 .col {
    width: 50%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g15-4 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .sm-grid-g15-4 .col {
    width: 33.333333333333336%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 768px) {
  .sm-grid-g15-3 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .sm-grid-g15-3 .col {
    width: 25%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 650px) {
  .xs-grid-g15-12 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .xs-grid-g15-12 .col {
    width: 100%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 650px) {
  .xs-grid-g15-6 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .xs-grid-g15-6 .col {
    width: 50%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
@media (max-width: 650px) {
  .xs-grid-g15-4 {
    margin-left: -7.5px;
    margin-right: -7.5px;
  }
  .xs-grid-g15-4 .col {
    width: 33.333333333333336%;
    padding: 0 7.5px;
    margin-bottom: 15px;
  }
}
/******* gutter 0 ******/
.grid-nog-1 {
  font-size: 0;
  margin-left: -0px;
  margin-right: -0px;
}
.grid-nog-1 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 8.333333333333334%;
  padding: 0 0px;
}
.grid-nog-2 {
  font-size: 0;
  margin-left: -0px;
  margin-right: -0px;
}
.grid-nog-2 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 16.666666666666668%;
  padding: 0 0px;
}
.grid-nog-3 {
  font-size: 0;
  margin-left: -0px;
  margin-right: -0px;
}
.grid-nog-3 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 0px;
}
.grid-nog-4 {
  font-size: 0;
  margin-left: -0px;
  margin-right: -0px;
}
.grid-nog-4 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 0px;
}
.grid-nog-5 {
  font-size: 0;
  margin-left: -0px;
  margin-right: -0px;
}
.grid-nog-5 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 41.66666666666667%;
  padding: 0 0px;
}
.grid-nog-6 {
  font-size: 0;
  margin-left: -0px;
  margin-right: -0px;
}
.grid-nog-6 .col {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 0px;
}
@media (max-width: 992px) {
  .md-grid-nog-12 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .md-grid-nog-12 .col {
    width: 100%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 992px) {
  .md-grid-nog-6 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .md-grid-nog-6 .col {
    width: 50%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 992px) {
  .md-grid-nog-4 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .md-grid-nog-4 .col {
    width: 33.333333333333336%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 992px) {
  .md-grid-nog-3 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .md-grid-nog-3 .col {
    width: 25%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .sm-grid-nog-12 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .sm-grid-nog-12 .col {
    width: 100%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .sm-grid-nog-6 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .sm-grid-nog-6 .col {
    width: 50%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .sm-grid-nog-4 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .sm-grid-nog-4 .col {
    width: 33.333333333333336%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 768px) {
  .sm-grid-nog-3 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .sm-grid-nog-3 .col {
    width: 25%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 650px) {
  .xs-grid-nog-12 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .xs-grid-nog-12 .col {
    width: 100%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 650px) {
  .xs-grid-nog-6 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .xs-grid-nog-6 .col {
    width: 50%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 650px) {
  .xs-grid-nog-4 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .xs-grid-nog-4 .col {
    width: 33.333333333333336%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
@media (max-width: 650px) {
  .xs-grid-nog-3 {
    margin-left: -0px;
    margin-right: -0px;
  }
  .xs-grid-nog-3 .col {
    width: 25%;
    padding: 0 0px;
    margin-bottom: 0px;
  }
}
/*- HEADER POUR TOUTES LES PAGES (LOGOS) */
.header {
  font-size: 0;
}
.header-left {
  font-size: initial;
  display: inline-block;
  vertical-align: middle;
  width: 9%;
  padding-left: 5px;
}
@media (min-width: 1100px) {
  .header-left {
    width: 13%;
  }
}
@media (max-width: 930px) {
  .header-left {
    margin-top: 5px;
    margin-left: 5px;
  }
}
@media (max-width: 290px) {
  .header-left {
    width: 100%;
    display: block;
    text-align: center;
  }
}
.header-right {
  font-size: initial;
  width: 89%;
  display: inline-block;
  vertical-align: middle;
  text-align: right;
}
@media (min-width: 1100px) {
  .header-right {
    width: 87%;
  }
}
@media (max-width: 930px) {
  .header-right {
    margin-top: 18px;
    margin-bottom: 30px;
  }
}
@media (max-width: 290px) {
  .header-right {
    width: 100%;
    display: block;
    text-align: center;
  }
}
.header-logo {
  text-align: center;
}
.header-logo-img {
  max-width: 180px;
  min-width: 100px;
  display: block;
  width: 100%;
  margin: auto;
}
@media (max-width: 1420px) {
  .header-logo-img {
    max-width: 130px;
  }
}
.header.fixed .header-right {
  margin-bottom: 0;
}
.header-top {
  display: inline-block;
}
@media (max-width: 1200px) {
  .header-top {
    margin-bottom: -60px;
    position: relative;
  }
}
.header-top-num,
.header-top-call,
.header-top-contact,
.header-top-lang {
  display: inline-block;
  vertical-align: middle;
}
.header-top-call,
.header-top-contact {
  letter-spacing: 1.2px;
  text-shadow: 1px 1.312px 1px #0a7d83;
}
@media (max-width: 1200px) {
  .header-top-call,
  .header-top-contact {
    display: inline-block;
  }
}
.header-top-call {
  padding-left: 40px;
  position: relative;
}
@media (max-width: 1205px) {
  .header-top-call {
    font-size: 0px;
    line-height: 5px;
    padding-left: 2px;
  }
}
@media (max-width: 930px) {
  .header-top-call {
    padding-left: 40px;
    font-size: inherit;
  }
}
@media (max-width: 640px) {
  .header-top-call {
    font-size: 0px;
    line-height: 5px;
    padding-left: 2px;
    text-align: center;
  }
}
.header-top-call:before {
  font-family: 'osiris';
  content: "\e906";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: absolute;
  left: 15px;
  font-size: 23px;
  height: 28px;
  top: 0;
  bottom: 0;
  margin: auto;
}
@media (max-width: 640px) {
  .header-top-call:before {
    left: 0;
    right: 0;
  }
}
.header-top-contact {
  padding-left: 40px;
  position: relative;
}
@media (max-width: 1205px) {
  .header-top-contact {
    font-size: 0px;
    line-height: 5px;
    padding-left: 2px;
  }
}
@media (max-width: 930px) {
  .header-top-contact {
    padding-left: 40px;
    font-size: inherit;
  }
}
@media (max-width: 640px) {
  .header-top-contact {
    font-size: 0px;
    line-height: 5px;
    padding-left: 2px;
    text-align: center;
  }
}
.header-top-contact:before {
  font-family: 'osiris';
  content: "\e90d";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: absolute;
  left: 15px;
  font-size: 10px;
  line-height: 15px;
  line-height: 10px;
  top: 50%;
  bottom: 0;
  margin-top: -5px;
}
@media (max-width: 640px) {
  .header-top-contact:before {
    left: 0;
    right: 0;
  }
}
.header-top-currency {
  border-radius: 3px;
  padding: 10px 15px 10px 25px;
  position: relative;
  display: inline-flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  vertical-align: middle;
}
.header-top-currency .currency-switcher {
  display: inline-block;
  text-decoration: none;
}
.header-top-currency .currency-switcher:hover {
  cursor: pointer;
}
.header-top-currency .currency-switcher.active:hover {
  cursor: default;
}
.header-top-currency .currency-switcher select {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: center;
}
.header-top-lang {
  border-radius: 3px;
  padding: 10px 15px 10px 25px;
  position: relative;
  display: inline-flex;
  gap: 5px;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.header-top-lang .language-switcher {
  display: inline-block;
}
.header-top-lang .language-switcher:hover {
  text-decoration: underline;
  cursor: pointer;
}
.header-top-lang .language-switcher.active {
  font-weight: bold;
}
.header-top-lang .language-switcher.active:hover {
  cursor: default;
  text-decoration: none;
}
.header-top-num {
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 10px 15px 10px 25px;
  position: relative;
}
@media (max-width: 930px) {
  .header-top-num {
    display: none;
  }
}
.header-top-num:before {
  font-family: 'osiris';
  content: "\e905";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: absolute;
  left: 15px;
  font-size: 18px;
  height: 17px;
  top: 0;
  bottom: 0;
  margin: auto;
  color: #777578;
}
.header-top-num a {
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 1.2px;
}
.header-top-num p {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0 10px;
}
.header-top-num-link {
  font-family: 'kanit-semibold';
  font-weight: 400;
}
.header-top-num-link:hover {
  color: #0e939a;
}
.header > .header-top-num {
  display: none;
}
.header > .header-top-num::before {
  display: none;
}
@media (max-width: 930px) {
  .header > .header-top-num {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110%;
    margin-left: -5%;
  }
  .header > .header-top-num a {
    font-weight: bold;
    letter-spacing: 0;
    font-size: 14px;
  }
  .header > .header-top-num a:before {
    font-family: 'osiris';
    content: "\e906";
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 23px;
    height: 28px;
  }
}
.page-header {
  position: relative;
  padding: 40px 0 0px;
  /* landing et destination */
}
@media (max-width: 480px) {
  .page-header {
    padding: 10px 0 0px;
  }
}
.page-header.no-subnavigation.page-header.no-subtitle {
  padding-bottom: 10px;
}
.page-header.no-subnavigation.page-header.no-subtitle .page-header-h1 {
  text-transform: uppercase;
}
@media (max-width: 480px) {
  .page-header.no-subnavigation.page-header.no-subtitle {
    padding-bottom: 30px;
  }
}
.page-header-breadcrumb {
  padding: 20px 30px;
  background-color: #15c6bc;
}
.page-header-breadcrumb span {
  color: #fff;
}
.page-header-breadcrumb span a {
  color: #fff;
}
.page-header-breadcrumb .breadcrumb_last {
  display: none;
}
.page-header.image {
  min-height: 740px;
  background-position: center center;
  background-size: cover !important;
  margin-bottom: -40px;
}
.page-header.image.noflats {
  min-height: 530px;
}
.page-header.image .page-header-h1 {
  text-transform: uppercase;
}
.page-header-h1 {
  position: relative;
  font-family: 'kanit-extralight';
  font-weight: 100;
  font-size: 65px;
  line-height: 70px;
  padding: 2% 6%;
  margin: 0;
  text-align: center;
  color: #fff;
}
@media (max-width: 1200px) {
  .page-header-h1 {
    padding: 0 3%;
    font-size: 55px;
    line-height: 60px;
  }
}
@media (max-width: 992px) {
  .page-header-h1 {
    padding: 0 3%;
    font-size: 45px;
    line-height: 50px;
  }
}
@media (max-width: 768px) {
  .page-header-h1 {
    padding: 0 3%;
    font-size: 38px;
    line-height: 43px;
  }
}
.page-header-h1-top-h1 {
  color: #fff;
  margin: 0 auto;
  width: 90%;
  font-family: 'kanit-extralight';
  font-weight: 100;
  font-size: 65px;
  line-height: 70px;
}
@media (max-width: 480px) {
  .page-header-h1-top-h1 {
    width: 100%;
    font-size: 55px;
    line-height: 60px;
  }
}
.page-header-h1-top-h1:after {
  position: absolute;
  bottom: 30px;
  left: 2%;
  font-family: 'osiris';
  content: "\e914";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
@media (max-width: 850px) {
  .page-header-h1-top-h1:after {
    font-size: 40px;
  }
}
@media (max-width: 650px) {
  .page-header-h1-top-h1:after {
    font-size: 30px;
  }
}
@media (max-width: 610px) {
  .page-header-h1-top-h1:after {
    font-size: 0;
  }
}
.page-header-h1-top-h1:before {
  position: absolute;
  bottom: 30px;
  right: 2%;
  font-family: 'osiris';
  content: "\e914";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
@media (max-width: 850px) {
  .page-header-h1-top-h1:before {
    font-size: 40px;
  }
}
@media (max-width: 650px) {
  .page-header-h1-top-h1:before {
    font-size: 30px;
  }
}
@media (max-width: 610px) {
  .page-header-h1-top-h1:before {
    font-size: 0;
  }
}
.page-header-h1.top-h2 {
  font-size: 40px;
  line-height: 45px;
  padding-bottom: 20px;
  text-transform: none;
}
.page-header-date {
  position: relative;
  font-family: 'kanit-extralight';
  font-weight: 100;
  font-size: 20px;
  line-height: 25px;
  padding: 0;
  margin: 0;
  text-align: center;
  color: #fff;
  padding-bottom: 100px;
}
.page-header-date a {
  color: #fff;
  font-size: 20px;
  line-height: 25px;
  font-family: 'kanit-extralight';
  font-weight: 100;
}
.page-header-landscape--img {
  display: block;
  width: 100%;
}
.breadcrumb-wrapper-programme {
  position: absolute;
  top: 0;
  left: 30px;
  font-size: 18px;
  line-height: 23px;
}
.breadcrumb-wrapper-programme span {
  color: #fff;
}
.breadcrumb-wrapper-programme a {
  color: #fff;
}
.breadcrumb-wrapper-programme a:hover {
  text-decoration: underline;
}
.breadcrumb-wrapper-programme .breadcrumb_last {
  display: none;
}
@media (max-width: 480px) {
  .breadcrumb-wrapper-programme {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 5px;
  }
}
.breadcrumb-wrapper-lot {
  position: absolute;
  top: 0;
  left: 30px;
  font-size: 18px;
  line-height: 23px;
}
.breadcrumb-wrapper-lot span {
  color: #fff;
}
.breadcrumb-wrapper-lot a {
  color: #fff;
}
.breadcrumb-wrapper-lot a:hover {
  text-decoration: underline;
}
.breadcrumb-wrapper-lot .breadcrumb_last {
  display: none;
}
@media (max-width: 480px) {
  .breadcrumb-wrapper-lot {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 25px;
  }
}
@media (max-width: 480px) {
  .breadcrumb-wrapper-lot .breadcrumb {
    display: block;
    padding: 0;
  }
}
.breadcrumb-wrapper-destination {
  position: absolute;
  top: 0;
  left: 2%;
  font-size: 18px;
  line-height: 23px;
}
@media (max-width: 480px) {
  .breadcrumb-wrapper-destination {
    position: relative;
    margin-bottom: 0;
    text-align: center;
  }
}
.breadcrumb-wrapper-destination span {
  color: #fff;
}
.breadcrumb-wrapper-destination a {
  color: #fff;
}
.breadcrumb-wrapper-destination a:hover {
  text-decoration: underline;
}
.breadcrumb-wrapper-destination .breadcrumb_last {
  display: none;
}
.breadcrumb-wrapper .breadcrumb_last {
  display: none;
}
.header {
  transition: all .3s ease;
}
.header.fixed {
  max-width: 1495px;
  margin: auto;
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top: 0 !important;
  z-index: 100;
  background-color: #ffffff;
  border-bottom: 1px solid #2d2c2a;
  padding: 0 15px;
}
@media screen and (max-width: 340px) {
  .header.fixed {
    padding: 0;
    text-align: center;
  }
}
.header.fixed .header-top {
  top: 0;
  margin-bottom: 0;
  position: relative;
  height: 50px;
}
@media (min-width: 931px) {
  .header.fixed .header-top {
    display: none;
  }
}
@media (max-width: 1200px) {
  .header.fixed .header-right {
    padding-left: 5%;
  }
}
@media (max-width: 1010px) {
  .header.fixed .header-right {
    padding-left: 5%;
  }
}
@media (max-width: 1080px) {
  .header.fixed .header-right {
    padding-left: 5%;
  }
}
@media (max-width: 660px) {
  .header.fixed .header-right {
    float: right;
  }
}
@media (max-width: 290px) {
  .header.fixed .header-right {
    width: 100%;
    display: block;
    text-align: center;
  }
}
.header.fixed .header-logo-img {
  max-width: 100px;
}
.header.fixed .nav .menu > li:first-child {
  border-left: 0;
}
@media (max-width: 1115px) {
  .header.fixed .nav .menu > li > a {
    font-size: 12px;
    line-height: 17px;
  }
}
@media (max-width: 975px) {
  .header.fixed .nav .menu > li > a {
    font-size: 11px;
    line-height: 16px;
  }
}
.stickybuttons {
  position: fixed;
  right: -15px;
  top: 10%;
  z-index: 4000;
}
@media (min-width: 1200px) {
  .stickybuttons {
    display: none;
  }
}
@media (max-width: 1115px) {
  .stickybuttons {
    right: -32px;
  }
}
.stickybuttons a {
  display: block;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: 13px 22px !important;
  padding-left: 49px !important;
  margin-left: 0 !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}
@media (max-width: 1115px) {
  .stickybuttons a {
    font-size: 0 !important;
    text-align: left !important;
  }
}
.stickybuttons .header-top-call:before {
  font-size: 38px;
  position: absolute !important;
  left: 13px !important;
  top: -12px !important;
}
@media (max-width: 1115px) {
  .stickybuttons .header-top-call:before {
    left: 16px !important;
    top: -9px !important;
    font-size: 33px;
  }
}
@media (max-width: 1205px) {
  .stickybuttons .header-top-call {
    font-size: 14px;
    line-height: 19px;
    padding-left: 49px !important;
  }
}
.stickybuttons .contact-message:before {
  font-size: 21px;
  left: 10px;
  top: 12px;
  position: absolute !important;
}
@media (max-width: 1115px) {
  .stickybuttons .contact-message:before {
    font-size: 19px;
    left: 11px;
    top: 12px;
  }
}
/*- FOOTER POUR TOUTES LES PAGES (LOGOS) */
.footer {
  margin-top: 10px;
  padding: 40px 15px;
  font-size: 0;
  color: white;
}
.footer p,
.footer li,
.footer a {
  color: white;
}
.footer-title {
  display: block;
  color: #0e939a;
  font-size: 19px;
  line-height: 24px;
  color: #53dce3;
  font-family: 'kanit-extralight';
  font-weight: 100;
  position: relative;
  padding-left: 45px;
}
.footer-title:before {
  position: absolute;
  left: 0;
  margin: auto;
  top: 0;
  bottom: 0;
  font-size: 28px;
}
.footer-border {
  display: inline-block;
  position: absolute;
  width: 1px;
  top: 10px;
  right: 10px;
  height: 130px;
  background-color: rgba(255, 255, 255, 0.21);
}
@media (max-width: 1150px) {
  .footer-border {
    height: 150px;
  }
}
@media (max-width: 768px) {
  .footer-border {
    position: relative;
    display: block;
    margin: 25px auto;
    height: 1px;
    width: 80%;
  }
}
.footer-logo {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 33.333333333333336%;
  padding: 0 15px;
}
@media (max-width: 1070px) {
  .footer-logo {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.footer-logo-text {
  max-width: 400px;
  font-size: 13px;
  margin-bottom: 0;
  display: inline-block;
  vertical-align: top;
}
@media (max-width: 768px) {
  .footer-logo-text {
    padding-left: 0;
  }
}
@media (max-width: 1070px) {
  .footer-logo {
    margin-bottom: 20px;
  }
}
@media (max-width: 1070px) {
  .footer-logo .footer-border {
    display: none;
  }
}
@media (max-width: 768px) {
  .footer-logo .footer-border {
    display: block;
  }
}
.footer-follow {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 16.666666666666668%;
  padding: 0 15px;
  text-align: center;
}
@media (max-width: 1070px) {
  .footer-follow {
    width: 33.333333333333336%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .footer-follow {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.footer-follow-title {
  margin-bottom: 30px;
  text-align: left;
}
.footer-follow-title:before {
  font-family: 'osiris';
  content: "\e904";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.footer-follow-link {
  border-radius: 50%;
  display: inline-block;
  vertical-align: top;
  width: 50px;
  height: 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  position: relative;
  margin-right: 20px;
  margin-bottom: 20px;
}
.footer-follow-link:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 26px;
  width: 13px;
  font-size: 25px;
}
.footer-follow-link:hover,
.footer-follow-link:focus {
  background-color: white;
  color: #4f4d50;
}
.footer-follow-link.facebook:after {
  font-family: 'osiris';
  content: "\e90b";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.footer-follow-link.youtube:after {
  font-family: 'osiris';
  content: "\e918";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  right: 12px;
}
.footer-follow-link.linkedin:after {
  font-family: 'osiris';
  content: "\e917";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  right: 12px;
}
.footer-follow-link.instagram:after {
  font-family: 'osiris';
  content: "\e916";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  right: 12px;
}
.footer-letter {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 15px;
}
@media (max-width: 1070px) {
  .footer-letter {
    width: 33.333333333333336%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .footer-letter {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.footer-letter-title:before {
  font-family: 'osiris';
  content: "\e90a";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  height: 30px;
}
.footer-letter-text,
.footer-letter-btn {
  margin-left: 45px;
}
.footer-letter-text {
  padding-right: 25px;
}
.footer-contact {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 15px;
}
@media (max-width: 1070px) {
  .footer-contact {
    width: 33.333333333333336%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .footer-contact {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
.footer-contact-title {
  padding-left: 35px;
}
.footer-contact-title:before {
  font-family: 'osiris';
  content: "\e913";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.footer-contact-el {
  margin-left: 45px;
  padding-left: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.footer-contact-el:before {
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  font-family: 'osiris';
  content: "\e90c";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  color: #53dce3;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 15px;
}
.footer-contact-el:hover,
.footer-contact-el:focus {
  padding-left: 40px;
}
.footer-contact-el:hover a,
.footer-contact-el:focus a {
  color: #53dce3;
}
.footer-contact-el:hover:before,
.footer-contact-el:focus:before {
  left: 5px;
}
.footer-contact-link {
  text-transform: uppercase;
  font-weight: 400;
  font-family: 'kanit-medium';
  letter-spacing: 0.3px;
}
.follow {
  padding: 35px 10px;
  background-color: #3eb1b7;
  position: relative;
  display: block;
  -webkit-transition: all all 0.4s ease ease;
  -moz-transition: all all 0.4s ease ease;
  -ms-transition: all all 0.4s ease ease;
  -o-transition: all all 0.4s ease ease;
  transition: all all 0.4s ease ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.follow:hover {
  background-position: 0 200%;
}
.follow:hover .follow-icon {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.follow-title {
  color: white;
  display: inline-block;
  font-weight: 400;
  font-family: 'kanit-medium';
  text-transform: uppercase;
  font-size: 28px;
  line-height: 33px;
  margin-bottom: 5px;
  margin-top: 0;
  position: relative;
}
@media (max-width: 992px) {
  .follow-title {
    font-size: 26px;
    line-height: 31px;
  }
}
@media (max-width: 768px) {
  .follow-title {
    font-size: 24px;
    line-height: 29px;
  }
}
.follow-subtitle {
  font-size: 17px;
  line-height: 22px;
  max-width: 500px;
  margin: 15px auto 30px auto;
  color: white;
  font-family: 'kanit-extralight';
  font-weight: 100;
}
@media (max-width: 992px) {
  .follow-subtitle {
    font-size: 16px;
    line-height: 21px;
  }
}
@media (max-width: 650px) {
  .follow-subtitle {
    max-width: 90%;
  }
}
.follow-link {
  display: inline-block;
  background-color: #3eb1b7;
  -webkit-transition: all all 0.4s ease ease;
  -moz-transition: all all 0.4s ease ease;
  -ms-transition: all all 0.4s ease ease;
  -o-transition: all all 0.4s ease ease;
  transition: all all 0.4s ease ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-right: 20px;
}
.follow-icon {
  margin: auto;
  content: '';
  width: 80px;
  height: 80px;
  line-height: 80px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  -webkit-transition: all all 0.4s ease ease;
  -moz-transition: all all 0.4s ease ease;
  -ms-transition: all all 0.4s ease ease;
  -o-transition: all all 0.4s ease ease;
  transition: all all 0.4s ease ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
@media (max-width: 1500px) {
  .follow-icon {
    right: 15%;
  }
}
@media (max-width: 1050px) {
  .follow-icon {
    right: 5%;
  }
}
@media (max-width: 850px) {
  .follow-icon {
    position: relative;
    margin-top: 15px;
    right: 0;
    width: 70px;
    height: 70px;
    line-height: 70px;
  }
}
@media (max-width: 650px) {
  .follow-icon {
    position: relative;
    margin-top: 15px;
    right: 0;
    width: 60px;
    height: 60px;
    line-height: 60px;
  }
}
.follow-icon.facebook:after {
  font-family: 'osiris';
  content: "\e90b";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.follow-icon.youtube:after {
  font-family: 'osiris';
  content: "\e918";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  right: 12px;
}
.follow-icon.linkedin:after {
  font-family: 'osiris';
  content: "\e917";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  right: 12px;
}
.follow-icon.instagram:after {
  font-family: 'osiris';
  content: "\e916";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  right: 12px;
}
.follow-icon:after {
  display: inline-block;
  line-height: 80px;
  vertical-align: middle;
  font-size: 50px;
  color: #3eb1b7;
  margin-top: -3px;
}
@media (max-width: 850px) {
  .follow-icon:after {
    font-size: 40px;
  }
}
@media (max-width: 650px) {
  .follow-icon:after {
    font-size: 30px;
  }
}
.bottom {
  font-size: 0;
}
.bottom-nav {
  text-align: right;
}
.bottom-nav-item {
  display: inline-block;
  vertical-align: top;
  border-right: 1px solid #0e939a;
  padding: 0 10px;
}
.bottom-nav-item:last-child {
  border: 0;
}
.bottom-nav-link {
  color: #0e939a;
}
.bottom-copyright,
.bottom-nav {
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 0 15px;
  vertical-align: middle;
}
@media (max-width: 768px) {
  .bottom-copyright,
  .bottom-nav {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 768px) {
  .bottom-copyright,
  .bottom-nav {
    text-align: center;
    margin: 5px 0;
  }
}
.bottom-copyright {
  margin-top: 15px;
  font-size: 12px;
  line-height: 17px;
}
/*-- NAVIGATION --*/
.nav {
  position: relative;
}
@media (max-width: 1200px) {
  .nav {
    width: 100%;
  }
}
@media (max-width: 930px) {
  .nav {
    display: none;
  }
}
.nav ul li:before {
  content: none;
}
.nav .menu {
  text-align: right;
  padding-left: 0;
  padding-top: 10px;
  position: relative;
  z-index: 2;
}
.nav .menu > li {
  display: inline-block;
  vertical-align: top;
  padding: 4px 20px 7px 20px;
  border-right: 1px solid #979797;
  position: relative;
}
@media (max-width: 1350px) {
  .nav .menu > li {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 1205px) {
  .nav .menu > li {
    padding-left: 0;
    padding-right: 0;
  }
}
.nav .menu > li:first-child {
  border-left: 1px solid #979797;
}
.nav .menu > li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}
.nav .menu > li:hover a:before {
  bottom: 0;
  width: 100%;
  -webkit-transition: all width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) ease;
  -moz-transition: all width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) ease;
  -ms-transition: all width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) ease;
  -o-transition: all width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) ease;
  transition: all width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1) ease;
  -webkit-transition: width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -moz-transition: width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -ms-transition: width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -o-transition: width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  transition: width 0.3s cubic-bezier(0.39, 0.575, 0.565, 1);
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.nav .menu > li > a {
  font-size: 15px;
  line-height: 20px;
  color: #2d2c2a;
  letter-spacing: 1px;
  font-family: 'kanit-semibold';
  font-weight: 400;
  text-transform: uppercase;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 7px;
  position: relative;
}
@media (max-width: 1630px) {
  .nav .menu > li > a {
    font-size: 13px;
    line-height: 18px;
  }
}
@media (max-width: 998px) {
  .nav .menu > li > a {
    font-size: 11px;
    line-height: 16px;
  }
}
.nav .menu > li > a:before {
  content: '';
  position: absolute;
  bottom: -10px;
  width: 0;
  height: 2px;
  background-color: #2d2c2a;
  -webkit-transition: width 0.3s 0.1s cubic-bezier(0.39, 0.575, 0.565, 1), bottom 0.3s 0.4s ease;
  -moz-transition: width 0.3s 0.1s cubic-bezier(0.39, 0.575, 0.565, 1), bottom 0.3s 0.4s ease;
  -ms-transition: width 0.3s 0.1s cubic-bezier(0.39, 0.575, 0.565, 1), bottom 0.3s 0.4s ease;
  -o-transition: width 0.3s 0.1s cubic-bezier(0.39, 0.575, 0.565, 1), bottom 0.3s 0.4s ease;
  transition: width 0.3s 0.1s cubic-bezier(0.39, 0.575, 0.565, 1), bottom 0.3s 0.4s ease;
  left: 0;
  right: 0;
  margin: auto;
}
.nav .menu > li > a img {
  display: none;
}
.nav .menu > li > a.no-upp {
  text-transform: none;
  font-family: 'kanit-light';
  font-weight: 100;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0px;
}
.nav .menu > li.menu-item-43 > a,
.nav .menu > li.menu-item-9408 > a {
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  line-height: 16px;
  text-transform: inherit;
}
@media (max-width: 1630px) {
  .nav .menu > li.menu-item-43 > a,
  .nav .menu > li.menu-item-9408 > a {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 992px) {
  .nav .menu > li.menu-item-43 > a,
  .nav .menu > li.menu-item-9408 > a {
    font-size: 14px;
    line-height: 19px;
  }
}
.nav .menu > li.menu-item-46 {
  border-right: 0;
}
.nav .menu > li.menu-item-46 > a {
  font-family: 'kanit-regular';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  line-height: 16px;
  text-transform: inherit;
}
@media (max-width: 1630px) {
  .nav .menu > li.menu-item-46 > a {
    font-size: 15px;
    line-height: 20px;
  }
}
@media (max-width: 992px) {
  .nav .menu > li.menu-item-46 > a {
    font-size: 14px;
    line-height: 19px;
  }
}
.nav .menu li.current-menu-item a {
  border-bottom: 2px #2d2c2a solid;
  padding-bottom: 5px;
}
.nav .menu li.current-menu-item a:before {
  display: none;
}
.nav .menu .menu-item-has-children {
  position: relative;
}
.nav .sub-menu {
  position: absolute;
  background-color: white;
  padding: 30px 15px 10px 15px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -moz-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  margin: auto;
  text-align: center;
  font-size: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -ms-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1), top 0.4s cubic-bezier(0.23, 1, 0.32, 1);
}
.nav .sub-menu > li a {
  text-transform: uppercase;
  display: inline-block;
  width: 200px;
  overflow: hidden;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 14px;
  line-height: 19px;
  padding-bottom: 20px;
  position: relative;
}
.nav .sub-menu > li a:before {
  font-family: 'osiris';
  content: "\e90c";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  -webkit-transition: all all 0.4s cubic-bezier(0.23, 1, 0.32, 1) ease;
  -moz-transition: all all 0.4s cubic-bezier(0.23, 1, 0.32, 1) ease;
  -ms-transition: all all 0.4s cubic-bezier(0.23, 1, 0.32, 1) ease;
  -o-transition: all all 0.4s cubic-bezier(0.23, 1, 0.32, 1) ease;
  transition: all all 0.4s cubic-bezier(0.23, 1, 0.32, 1) ease;
  -webkit-transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -ms-transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.4s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
}
.nav .sub-menu > li a:hover img {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.nav .sub-menu > li a:hover:before {
  padding-left: 15px;
}
.nav .sub-menu li {
  padding: 0 5px;
  font-size: initial;
  display: inline-block;
  vertical-align: top;
  text-align: center;
}
.nav .sub-menu img {
  max-width: 100%;
  height: auto;
  transform-origin: center bottom;
  -webkit-transition: all all 0.4s ease ease;
  -moz-transition: all all 0.4s ease ease;
  -ms-transition: all all 0.4s ease ease;
  -o-transition: all all 0.4s ease ease;
  transition: all all 0.4s ease ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  margin-bottom: 10px;
}
.pagination {
  padding: 15px 0;
  margin-top: 25px;
}
.pagination-page {
  font-size: 18px;
  line-height: 23px;
  font-family: 'kanit-light';
  font-weight: 100;
  margin: 0 7px;
}
.pagination-page a {
  font-size: 18px;
  line-height: 23px;
  font-family: 'kanit-light';
  font-weight: 100;
  color: #3eb1b7;
}
.pagination-prev {
  margin-rigth: 15px;
}
.pagination-prev a {
  font-size: 18px;
  line-height: 23px;
  font-family: 'kanit-light';
  font-weight: 100;
  color: #3eb1b7;
}
.pagination-next {
  margin-left: 15px;
}
.pagination-next a {
  font-size: 18px;
  line-height: 23px;
  font-family: 'kanit-light';
  font-weight: 100;
  color: #3eb1b7;
}
@media (max-width: 480px) {
  .navigation .flatform {
    display: none;
  }
}
#menu-item-43 .sub-menu li,
#menu-item-46 .sub-menu li,
#menu-item-9408 .sub-menu li {
  margin-bottom: 10px;
}
.mobilenav .active {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-color: red;
  z-index: 999;
}
.mobilenav-wrapper {
  display: none;
  opacity: 0;
  transition: all .3s ease;
}
@media (max-width: 930px) {
  .mobilenav-wrapper.active {
    z-index: 100;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    background-color: #fff;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.mobilenav-wrapper .menu-menu-principal-container {
  overflow: auto;
}
.mobilenav-wrapper .menu-menu-principal-container > ul,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu {
  margin: 0;
  padding: 0;
  text-align: center;
}
.mobilenav-wrapper .menu-menu-principal-container > ul > li,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu > li,
.mobilenav-wrapper .menu-menu-principal-container > ul .menu-item,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu .menu-item {
  list-style: none;
}
.mobilenav-wrapper .menu-menu-principal-container > ul > li img,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu > li img,
.mobilenav-wrapper .menu-menu-principal-container > ul .menu-item img,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu .menu-item img {
  display: none;
}
.mobilenav-wrapper .menu-menu-principal-container > ul li,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu li {
  padding: 0 15px;
  margin: 15px 0;
}
.mobilenav-wrapper .menu-menu-principal-container > ul li > a,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu li > a {
  font-family: 'kanit-semibold';
  font-weight: 400;
  text-transform: uppercase;
  color: #2d2c2a;
  font-size: 18px;
  line-height: 23px;
}
.mobilenav-wrapper .menu-menu-principal-container > ul .sub-menu > li,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu .sub-menu > li {
  margin: 5px 0;
}
.mobilenav-wrapper .menu-menu-principal-container > ul .sub-menu > li a,
.mobilenav-wrapper .menu-menu-principal-container .sub-menu .sub-menu > li a {
  font-family: 'kanit-regular';
  font-weight: 400;
  text-transform: inherit;
  color: #1cb3aa;
  font-size: 18px;
  line-height: 23px;
}
.mobilenav-wrapper .menu-menu-principal-container li.menu-item-has-children {
  border-right: none;
  border-left: none;
}
.burger {
  position: relative;
  display: inline-block;
  width: 40px;
  margin: 0 15px 0 5px;
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;
}
.burger-wrapper {
  display: none;
  height: 70px;
  text-align: right;
}
@media (max-width: 930px) {
  .burger-wrapper {
    display: inline-block;
  }
}
.burger-child {
  transition: all .3s ease;
  opacity: 1;
  display: block;
  height: 4px;
  margin: 5px 0;
  background-color: #000;
  transform: rotate(0deg);
}
.burger.active .burger-child {
  opacity: 0;
}
.burger.active .burger-child:first-child {
  transform: rotate(45deg) translateY(8px) translateX(5px);
  opacity: 1;
}
.burger.active .burger-child:last-child {
  transform: rotate(-45deg) translateY(-8px) translateX(5px);
  opacity: 1;
}
.cross {
  position: relative;
  display: inline-block;
  width: 40px;
  margin: 0 15px 0 5px;
  background-color: transparent;
  cursor: pointer;
  vertical-align: middle;
}
.cross-wrapper {
  position: absolute;
  top: 33px;
  right: 18px;
  display: none;
  height: 70px;
  text-align: right;
}
@media (max-width: 930px) {
  .cross-wrapper {
    display: inline-block;
  }
}
.cross-child {
  transition: all .3s ease;
  opacity: 1;
  display: block;
  height: 4px;
  margin: 5px 0;
  background-color: #000;
  transform: rotate(0deg);
}
.cross.active .cross-child {
  opacity: 0;
}
.cross.active .cross-child:first-child {
  transform: rotate(45deg) translateY(8px) translateX(5px);
  opacity: 1;
}
.cross.active .cross-child:last-child {
  transform: rotate(-45deg) translateY(-8px) translateX(5px);
  opacity: 1;
}
a.mt-share-inline-bar-sm img {
  width: 50px;
  height: auto;
  border: 0px;
}
a.mt-share-inline-bar-sm:hover {
  z-index: 50;
  transform: scale3d(1.075, 1.075, 1.075);
}
a.mt-share-inline-bar-sm {
  display: inline-block;
  width: 64px;
  height: 48px;
  border-radius: 0px;
  margin-right: 0px;
  text-align: center;
  position: relative;
  transition: all 100ms ease-in;
  transform: scale3d(1, 1, 1);
}
.mt-google:hover {
  background-color: #e15f4f;
}
.mt-google {
  background-color: #dd4b39;
}
.mt-linkedin:hover {
  background-color: #1087c0;
}
.mt-linkedin {
  background-color: #0e76a8;
}
.mt-twitter:hover {
  background-color: #08bbff;
}
.mt-twitter {
  background-color: #00acee;
}
.mt-facebook:hover {
  background-color: #4264aa;
}
.mt-facebook {
  background-color: #3b5998;
}
.mt-pinterest:hover {
  ackground-color: #dd2a30;
}
.mt-pinterest {
  background-color: #cc2127;
}
body {
  max-width: 1495px;
  margin-right: auto;
  margin-bottom: auto;
  margin-left: auto;
}
[data-href] {
  cursor: pointer;
}
.wpb_wrapper > .wpb_text_column.wpb_content_element {
  margin: auto;
  position: relative;
  width: 80%;
}
@media (max-width: 1300px) {
  .wpb_wrapper > .wpb_text_column.wpb_content_element {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .wpb_wrapper > .wpb_text_column.wpb_content_element {
    width: 90%;
  }
}
@media (max-width: 480px) {
  .wpb_wrapper > .wpb_text_column.wpb_content_element {
    width: 95%;
  }
}
.clearfix:after,
.row:after,
.clients-bloc:after,
.clients-bloc-overlay:after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  visibility: hidden;
}
.clearfix,
.row,
.clients-bloc,
.clients-bloc-overlay {
  display: inline-block;
}
.clearfix,
.row,
.clients-bloc,
.clients-bloc-overlay {
  display: block;
}
/*!** _home/Fichier destiné aux styles des titres ***/
/*!** _home/ Section haut de page ***/
/* HOME */
.home .top {
  text-align: center;
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  padding-top: 80px;
}
.home .top .breadcrumb_last {
  display: none;
}
.home .top.navigation {
  background-image: url('../images/palmier.jpg');
  padding-bottom: 200px;
  margin-bottom: -150px;
}
.home .top.no-navigation {
  padding-bottom: 30px;
}
.home .top.no-navigation .flatform {
  margin-top: 0;
}
@media (max-width: 1350px) {
  .home .top {
    padding: 40px 0 200px 0;
  }
}
.home .top .top-h1 {
  max-width: 900px;
  margin: auto;
  font-size: 32px;
  line-height: 37px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: white;
  font-weight: 400;
  font-family: 'kanit-medium';
  text-align: center;
  margin-bottom: 40px;
}
@media (max-width: 992px) {
  .home .top .top-h1 {
    font-size: 28px;
    line-height: 33px;
  }
}
@media (max-width: 768px) {
  .home .top .top-h1 {
    font-size: 25px;
    line-height: 30px;
  }
}
.home .top-entries {
  padding-left: 0;
  display: inline-block;
  text-align: center;
  width: 100%;
}
.home .top-entries-el {
  text-align: center;
}
.home .top-entries-link {
  display: inline-block;
  color: white;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 400;
  font-family: 'kanit-medium';
  font-size: 16px;
  line-height: 21px;
  text-shadow: 1px 1.312px 1px #0a7d83;
  background-color: rgba(63, 181, 187, 0.55);
  padding: 15px 0;
  width: 100%;
  max-width: 320px;
  border-radius: 3px;
  border: 1px solid white;
  box-shadow: 0px 2px 32px 0px rgba(0, 101, 106, 0.35);
}
@media (max-width: 768px) {
  .home .top-entries-link {
    font-size: 12px;
    line-height: 17px;
  }
}
.home .top-entries-link:hover {
  background-color: #3fb5bb;
  box-shadow: 0px 3px 40px 0px rgba(0, 101, 106, 0.55);
}
.home .top-entries-link.hide {
  opacity: 0.6;
}
.home .top-title {
  position: relative;
}
.home .top-pastille {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media (max-width: 1200px) {
  .home .top-pastille {
    display: none;
  }
}
.home .top-pastille-container {
  position: relative;
  width: 120px;
  height: 120px;
  line-height: 120px;
  -webkit-transform: rotate(5deg);
  -moz-transform: rotate(5deg);
  -ms-transform: rotate(5deg);
  -o-transform: rotate(5deg);
  transform: rotate(5deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  border-radius: 60px;
  vertical-align: middle;
}
.home .top-pastille-container:before {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  font-size: 120px;
  font-family: 'osiris';
  content: "\e915";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.home .top-pastille-container p {
  position: relative;
  z-index: 1;
  padding: 5px;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  font-weight: 400;
  font-family: 'kanit-medium';
  text-transform: uppercase;
  color: white;
  font-size: 12px;
  line-height: 17px;
}
.home .top-pastille-container p strong {
  font-family: 'kanit-bold';
  font-weight: 900;
  color: white;
}
.flats .pagination {
  display: none;
}
/*!** _home/ Section haut de page ***/
.flats {
  width: 80%;
  margin: auto;
  position: relative;
}
@media (max-width: 650px) {
  .flats {
    width: 90%;
  }
}
/*!** _home/ Section pays ***/
.countries {
  padding: 40px 0;
  text-align: center;
}
@media (max-width: 650px) {
  .countries {
    padding-bottom: 0;
  }
}
.countries-title.animated,
.countries-subtitle.animated {
  -webkit-animation-name: bottomToTop;
  -moz-animation-name: bottomToTop;
  -o-animation-name: bottomToTop;
  animation-name: bottomToTop;
  -webkit-animation-duration: 1s;
  -moz-animation-duration: 1s;
  -o-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  -o-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-delay: 0;
  -moz-animation-delay: 0;
  -ms-animation-delay: 0;
  -o-animation-delay: 0;
  animation-delay: 0;
  -webkit-animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  /* Chrome, Safari, Opera */
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.countries-subtitle {
  margin-bottom: 50px;
}
.countries-bloc {
  margin-top: 25px;
}
/*!** _home/ Section haut de page ***/
.investissement {
  background-attachment: fixed;
  text-align: center;
  max-width: 750px;
  margin: auto;
}
.investissement-img {
  display: inline-block;
  min-height: 150px;
  line-height: 150px;
}
.investissement-img img {
  display: inline-block;
  vertical-align: middle;
}
.investissement-text {
  max-width: 580px;
  margin: auto;
  margin-top: 20px;
  font-size: 16px;
  line-height: 21px;
}
.investissement h4 {
  margin-top: 10px;
  padding: 0 5%;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0;
}
.investissement .col {
  padding: 0 50px;
}
@media (max-width: 992px) {
  .investissement .col {
    padding: 0 30px;
  }
}
@media (max-width: 768px) {
  .investissement .col:first-child {
    padding-bottom: 40px;
  }
}
.investissement .col:first-child:before {
  content: '';
  height: 40%;
  width: 1px;
  position: absolute;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  top: 20%;
  bottom: 0;
  margin: auto;
}
@media (max-width: 768px) {
  .investissement .col:first-child:before {
    top: initial;
    bottom: 0;
    width: 50%;
    height: 1px;
    left: 0;
  }
}
.news {
  text-align: center;
  padding: 40px 10px;
}
.news-btn {
  margin-top: 40px;
}
.news-title {
  margin-bottom: 30px;
}
.google-search td {
  border-color: transparent !important;
}
.top-pastille {
  transition: all 0.3s ease-out;
}
.top-pastille:hover {
  transform: rotate(-20deg);
}
.top-pastille-container.property-yield {
  right: 20px;
  text-align: center;
  position: absolute;
  top: 30%;
  width: 90px;
  height: 90px;
  line-height: 90px;
  font-size: 10px;
  font-family: 'kanit-bold';
}
@media (max-width: 1400px) {
  .top-pastille-container.property-yield {
    top: 28%;
  }
}
@media (max-width: 1300px) {
  .top-pastille-container.property-yield {
    top: 22%;
  }
}
@media (max-width: 1250px) {
  .top-pastille-container.property-yield {
    top: 25%;
  }
}
.top-pastille-container.property-yield p {
  font-size: 10px;
  font-family: 'kanit-bold';
}
.top-pastille-container.property-yield span {
  font-family: 'kanit-medium';
  color: #ffffff;
  font-size: 20px;
}
.top-pastille-container.property-yield:before {
  font-size: 90px;
  color: rgba(62, 177, 183, 0.75);
  transition: all 0.3s ease-out;
}
.top-pastille-container.property-yield p {
  top: -5px;
}
.flatbox-content.hover .top-pastille-container:before {
  color: #3eb1b7;
}
.references h2 {
  font-size: 35px;
}
.clients-bloc {
  position: relative;
  display: block;
  margin: auto;
  padding: 0px 0 40px;
  margin-left: -15px;
  margin-right: -15px;
  /*.margin-for-shortcode();*/
  text-align: center;
}
.clients-bloc blockquote {
  padding: 0;
  margin: 0;
}
.clients-bloc blockquote p {
  padding: 0;
  margin: 0;
}
.clients-bloc .title-trace {
  padding-top: 0;
}
.clients-bloc .title-trace--title {
  padding-top: 0;
}
.clients-bloc .title-trace--title .icon {
  color: #fe5a3a;
}
.clients-bloc-wrapper {
  position: relative;
}
.clients-bloc-overlay {
  display: block;
  margin-left: -15px;
  margin-right: -15px;
  font-size: initial;
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 83.33333333333334%;
  padding: 0 15px;
  color: white;
  overflow-x: hidden;
  padding: 25px 0;
  margin-top: 20px !important;
}
@media (max-width: 1050px) {
  .clients-bloc-overlay {
    padding: 30px;
  }
}
@media (max-width: 750px) {
  .clients-bloc-overlay {
    width: 100%;
    padding: 20px;
  }
}
@media (max-width: 550px) {
  .clients-bloc-overlay {
    padding: 10px;
  }
}
.clients-bloc-margin {
  margin: 0 40px;
  overflow: hidden;
}
.clients-bloc-ul {
  -webkit-transition: all transform 0.3s ease;
  -moz-transition: all transform 0.3s ease;
  -ms-transition: all transform 0.3s ease;
  -o-transition: all transform 0.3s ease;
  transition: all transform 0.3s ease;
  -webkit-transition: transform 0.3s;
  -moz-transition: transform 0.3s;
  -ms-transition: transform 0.3s;
  -o-transition: transform 0.3s;
  transition: transform 0.3s;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  width: 100%;
  text-align: center;
  height: 80px;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex!important;
}
@media (max-width: 980px) {
  .clients-bloc-ul {
    padding-left: 0;
  }
}
.clients-bloc-ul.slider {
  text-align: center;
}
.clients-bloc-item {
  font-size: initial;
  display: inline-block;
  vertical-align: top;
  width: 25%;
  padding: 0 15px;
  height: 100%;
  text-align: center;
  vertical-align: middle;
  position: relative;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex!important;
  -webkit-transition: all all 0.3s ease ease;
  -moz-transition: all all 0.3s ease ease;
  -ms-transition: all all 0.3s ease ease;
  -o-transition: all all 0.3s ease ease;
  transition: all all 0.3s ease ease;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  color: white;
}
@media (max-width: 980px) {
  .clients-bloc-item {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 550px) {
  .clients-bloc-item {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 980px) {
  .clients-bloc-item {
    margin-bottom: 20px;
  }
  .clients-bloc-item img {
    max-width: 90%;
  }
}
.clients-bloc-item img {
  display: block;
  margin: auto;
  max-height: 80px;
}
@media (max-width: 750px) {
  .clients-bloc-item img {
    max-width: 180px;
  }
}
.clients-bloc-item:after {
  content: '';
  display: inline-block;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.11);
  width: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}
@media (max-width: 980px) {
  .clients-bloc-item:after {
    display: none;
  }
}
.clients-bloc-item:last-child:after {
  display: none;
}
@media (max-width: 550px) {
  .clients-bloc-item img {
    width: 100%;
  }
}
.clients-bloc-navigation {
  position: absolute;
  top: 20px;
  right: 0;
  left: 0;
  margin: auto;
  height: 100%;
  line-height: 100%;
  font-size: 60px;
  color: grey;
  z-index: 9;
}
@media (max-width: 750px) {
  .clients-bloc-navigation {
    font-size: 50px;
    color: white;
  }
}
.clients-bloc-navigation.next {
  -webkit-transition: all margin-right 0.3s ease ease;
  -moz-transition: all margin-right 0.3s ease ease;
  -ms-transition: all margin-right 0.3s ease ease;
  -o-transition: all margin-right 0.3s ease ease;
  transition: all margin-right 0.3s ease ease;
  -webkit-transition: margin-right 0.3s ease;
  -moz-transition: margin-right 0.3s ease;
  -ms-transition: margin-right 0.3s ease;
  -o-transition: margin-right 0.3s ease;
  transition: margin-right 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  margin-right: 0;
  width: 150px;
}
@media (max-width: 750px) {
  .clients-bloc-navigation.next {
    right: -10px;
  }
}
.clients-bloc-navigation.next:hover {
  margin-right: -25px;
  cursor: pointer;
}
.clients-bloc-navigation.next img {
  width: 20px;
}
.clients-bloc-navigation.prev {
  -webkit-transition: all margin-left 0.3s ease ease;
  -moz-transition: all margin-left 0.3s ease ease;
  -ms-transition: all margin-left 0.3s ease ease;
  -o-transition: all margin-left 0.3s ease ease;
  transition: all margin-left 0.3s ease ease;
  -webkit-transition: margin-left 0.3s ease;
  -moz-transition: margin-left 0.3s ease;
  -ms-transition: margin-left 0.3s ease;
  -o-transition: margin-left 0.3s ease;
  transition: margin-left 0.3s ease;
  -webkit-backface-visibility: hidden;
  /* Chrome, Safari, Opera */
  backface-visibility: hidden;
  margin-left: 0;
  width: 150px;
  /*.prefixed(transform, rotate(180deg));*/
}
@media (max-width: 750px) {
  .clients-bloc-navigation.prev {
    left: -10px;
  }
}
.clients-bloc-navigation.prev img {
  width: 20px;
  transform: rotate(180deg);
}
.clients-bloc-navigation.prev:hover {
  margin-left: -25px;
  cursor: pointer;
}
.clients-bloc-link {
  display: inline-block;
  margin: 30px auto 0;
  /*.btn-orange();*/
}
